import { Delete24Regular } from '@fluentui/react-icons'
import { t } from '@transifex/native'
import { ElementRef, forwardRef } from 'react'
import { NavLink } from 'react-router-dom'

import { NavigationComponentProps, NavigationSectionKey } from '../../../../helpers/navigation'
import { getGlobalTrashTag } from '../../../../selectors/trashTagsSelectors'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { useDefaultHomeUrl } from '../../../hooks/useDefaultHomeUrl'
import { SearchFilterKey } from '../../../hooks/useSearchFilters'
import useSearchRouteBuilder from '../../../hooks/useSearchRouteBuilder'
import { NavigationSection, NavigationSectionHeaderLink } from '../primitives/NavigationSection'

export const TrashNavigationSection = forwardRef<ElementRef<typeof NavLink>, NavigationComponentProps>(
  ({ isExpanded, ...props }, ref) => {
    const globalTrashTag = useAppSelector(getGlobalTrashTag)
    const defaultHomeUrl = useDefaultHomeUrl()
    const { getSearchRoute } = useSearchRouteBuilder()

    return (
      <NavigationSection>
        <NavigationSectionHeaderLink
          label={t('Trash')}
          isExpanded={Boolean(isExpanded)}
          ref={ref}
          to={globalTrashTag?.id ? getSearchRoute(SearchFilterKey.TRASH, globalTrashTag.id) : defaultHomeUrl}
          section={NavigationSectionKey.TRASH}
          {...props}
        >
          <Delete24Regular className="w-7 shrink-0 text-berry.4" />
        </NavigationSectionHeaderLink>
      </NavigationSection>
    )
  },
)
TrashNavigationSection.displayName = 'TrashNavigationSection'
