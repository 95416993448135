import { OpointAuthProvider, useOpointAuth, useOpointAuthState } from '@opoint/authjs-react'
import { Toaster, ToastProvider, TooltipProvider } from '@opoint/infomedia-storybook'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ReactNode, StrictMode, useEffect, useState } from 'react'
import { render } from 'react-dom'
import Modal from 'react-modal'
import { Provider } from 'react-redux'
// eslint-disable-next-line import/no-unresolved
import SvgIcons from './components/common/Icon/sprites/symbol-defs.svg?react'
import { useResizeObserverErrorDisabled } from './components/hooks/useResizeObserverErrorDisabled'
import config from './opoint/common/config'
import Routes from './routes'
import store from './store'

// Import Tailwind before our global styles to prevent it from overriding them.
import './index.css' // Tailwind
import './components/styles.scss'
import './sentry'
import './transifex'

import { CountryCodeFetchSuccessAction } from './actions/settings'
import { captureException } from './helpers/sentry'
import ErrorBoundary from './components/common/ErrorBoundary'
import { queryClient } from './queryClient'
import { useAppDispatch } from './components/hooks/useAppDispatch'

Modal.setAppElement('#root')

const oReq = new XMLHttpRequest()
oReq.addEventListener('load', async () => {
  let response
  try {
    response = JSON.parse(oReq.responseText)
  } catch (e) {
    response = { isoCode: 'en-GB' }
  }

  store.dispatch<CountryCodeFetchSuccessAction>({ type: 'COUNTRY_CODE_FETCH_SUCCESS', payload: response })

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  bootstrapApp()
})

// eslint-disable-next-line @typescript-eslint/no-use-before-define
oReq.addEventListener('error', bootstrapApp)
// eslint-disable-next-line @typescript-eslint/no-use-before-define
oReq.addEventListener('timeout', bootstrapApp)
oReq.open('GET', config.url.api('/users/location/?format=json'))
oReq.timeout = 400
oReq.send()

async function setupSentryReplay() {
  const { initReplay } = await import('./sentry-replay')
  initReplay()
}

function AuthWrapper({ children }: { children: ReactNode }) {
  const authState = useOpointAuthState()
  const auth = useOpointAuth()
  const dispatch = useAppDispatch()
  const [dispatched, setDispatched] = useState<boolean>(false)

  useEffect(() => {
    config.auth = auth
  }, [auth])

  // *TODO: Simplify (unify) the auth state handling
  // https://infomediacorp.atlassian.net/browse/FE-10292
  if (authState && !dispatched) {
    dispatch({
      type: 'LOG_IN',
      payload: { token: authState.token.payload, user: authState.user },
    })
    setDispatched(true)
  }

  return <>{children}</>
}

// This calls a hook, so it needs to be a regular function component
function AppRoot() {
  // TODO: Remove once "@tanstack/react-virtual" is out of beta
  // https://infomediacorp.atlassian.net/jira/software/c/projects/FE/boards/182/backlog?view=detail&selectedIssue=FE-10115
  useResizeObserverErrorDisabled()

  // Lazy-load Sentry Replay, to get a faster initial page load and smaller initial bundle size
  useEffect(() => {
    try {
      setupSentryReplay()
    } catch (error) {
      captureException(error)
    }
  }, [])

  return (
    <Provider store={store}>
      <ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag('location', 'Root')
        }}
        reloadButton={true}
        errorImg={true}
      >
        <OpointAuthProvider
          host={config.url.auth()}
          callback={config.url.callback()}
          options={{ autologin: false, persistentImpersonation: true }}
        >
          <AuthWrapper>
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools initialIsOpen={false} />
              <StrictMode>
                <TooltipProvider>
                  <SvgIcons />
                  <Routes />
                </TooltipProvider>
              </StrictMode>
            </QueryClientProvider>
          </AuthWrapper>
        </OpointAuthProvider>
      </ErrorBoundary>
    </Provider>
  )
}

function bootstrapApp() {
  render(<AppRoot />, document.getElementById('root'))
  render(
    <ToastProvider>
      <Toaster />
    </ToastProvider>,
    document.getElementById('alert'),
  )
}
