import { T } from '@transifex/react'
import { Step } from 'react-joyride'

import { REPORTS_TOUR_KEY } from '../../../constants/localStorage'
import ProductTour from '../ProductTour'
import { disabledBeaconAndAnimation, disabledScrolling } from '../constants'
import useDelayTour from '../useDelayTour'
import { prependHash } from '../utility'

export enum ReportsTourTarget {
  STEP_1 = 'RT_STEP_1',
}

const steps: Step[] = [
  {
    target: prependHash(ReportsTourTarget.STEP_1),
    content: (
      <T _str="You can change the order of the articles and you can edit the article if you wish to write a summary or add a comment." />
    ),
    showSkipButton: false,
    ...disabledBeaconAndAnimation,
    ...disabledScrolling,
  },
]

const ReportsTour = () => {
  const show = useDelayTour(true, 1000) // Delay the tour, to make sure the reports article list are rendered.

  if (!show) {
    return null
  }

  return <ProductTour tourKey={REPORTS_TOUR_KEY} steps={steps} />
}

export default ReportsTour
