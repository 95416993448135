import { CommentMultiple24Regular } from '@fluentui/react-icons'
import { ArticlePreviewRoot, Button, cn, isSocialArticle, splitBodyTextToParagraphs } from '@opoint/infomedia-storybook'
import { T } from '@transifex/react'
import { useRef, useState } from 'react'

import { useDispatch } from 'react-redux'

import { isEmpty } from 'ramda'
import { articleIdFromIds, getArticleUrl, LogActions } from '../../../opoint/articles'
import { getEditedArticlesState } from '../../../selectors/articlesSelectors'
import { getAlteredArticleComments } from '../../../selectors/commentsSelectors'
import {
  getAutoTranslationRemainingQuota,
  getCommentGroups,
  getTranslateAuto,
  getTranslateTo,
  getTranslateToLanguageInFullname,
  hasDisplayPrintPdfLink,
  isAllTagsHidden,
  isAutoTranslationsOn,
} from '../../../selectors/settingsSelectors'
import { getArticleModalStatus, isEditArticleModalOpen } from '../../../selectors/uiSelectors'
import { ExpansionMode } from '../../constants/common'
import { useAppSelector } from '../../hooks/useAppSelector'
import useIsECBUser from '../../hooks/useIsECBUser'

import FullscreenIFrameDialog from '../../common/FullscreenIFrameDialog'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { M360Article } from '../../../opoint/articles/types'
import ArticleInnerWrapper from './ArticleInnerWrapper'
import ArticleFooter from './common/ArticleFooter/ArticleFooter'
import Disclaimer from './common/Disclaimer/Disclaimer'
import IdenticalArticles from './common/IdenticalArticles/IdenticalArticles'
import PreviewArticle from './PreviewArticle'
import PreviewSocial from './PreviewSocial/PreviewSocial'

type ArticlePreviewProps = {
  article: M360Article
  editMode?: boolean
  editable?: boolean
  hideActionButtons?: boolean
  identical?: number
  isAlertView?: boolean
  isProfileEditor: boolean
  onContentChange?: (any) => void
  originalArticle: M360Article
  isAchiveLayout?: boolean
  index: number
  expansion?: ExpansionMode
  onActiveArticleChange?: (index: number) => void
  className?: string
}

const ArticlePreview = ({
  article,
  editMode = false,
  editable = false,
  hideActionButtons = false,
  identical = 0,
  isProfileEditor,
  onContentChange,
  originalArticle,
  isAlertView = false,
  isAchiveLayout = false,
  expansion = ExpansionMode.DISALLOWED,
  index,
  className,
  onActiveArticleChange,
}: ArticlePreviewProps) => {
  const [expanded, setExpanded] = useState(expansion === ExpansionMode.FORCED)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { paragraphsToExpand } = splitBodyTextToParagraphs(article?.body?.text)
  const dispatch = useDispatch()
  const appDispatch = useAppDispatch()

  const translateToLanguage = useAppSelector(getTranslateToLanguageInFullname)
  const translateToLanguageCode = useAppSelector(getTranslateTo)
  const translateAuto = useAppSelector(getTranslateAuto)
  const remainingTranslationQuota = useAppSelector(getAutoTranslationRemainingQuota)
  const editedState = useAppSelector(getEditedArticlesState)
  const hasPrintPdfLink = useAppSelector(hasDisplayPrintPdfLink)
  const commentGroups = useAppSelector(getCommentGroups)
  const alteredArticleComments = useAppSelector(getAlteredArticleComments)
  const globalAutoTranslateOn = useAppSelector(isAutoTranslationsOn)
  const isEditModalOpen = useAppSelector(isEditArticleModalOpen)
  const hideAllTags = useAppSelector(isAllTagsHidden)

  const shouldBeExpanded = !paragraphsToExpand || paragraphsToExpand?.length === 0
  const allowExpanded = expansion !== ExpansionMode.DISALLOWED
  const activeArticle = article || originalArticle
  const { id_site, id_article, translated, url, content_protected, orig_url, header } = activeArticle
  const articleModal = useAppSelector(getArticleModalStatus)
  const isECBUser = useIsECBUser()

  type ArticlePartsRefsType = {
    bottomActions: HTMLElement
  }
  const articlePartsRefs = useRef<ArticlePartsRefsType>({
    // @ts-expect-error: Muted so we could enable TS strict mode
    bottomActions: null,
  })

  const { identical_documents: { document: identicalDocuments = [] } = {} } = originalArticle || {}

  const articleAction = (action: LogActions[]) => {
    if (activeArticle) {
      appDispatch({
        type: 'LOG_ARTICLE_ACTION',
        payload: {
          id_site,
          id_article,
          action,
        },
      })
    }
  }

  const toggleExpanded = () => {
    if (!expanded) {
      articleAction([LogActions.ArticleConsumedClick])
    }
    setExpanded((expanded) => !expanded)
  }

  const handleOpenCommentsModal = () => {
    appDispatch({
      type: 'OPEN_ARTICLE_COMMENTS_MODAL',
      // @ts-expect-error: Muted so we could enable TS strict mode
      payload: { id_site, id_article, article_header: header.text },
    })
  }

  const openModal = () => {
    setIsModalOpen(true)
    articleAction([LogActions.PDFViewed])
  }

  const translateArticleHandler = () => {
    dispatch({
      type: 'FETCH_SINGLE_ARTICLE',
      //TODO: this doesn't match the payload type, we should assess how to fix this when modernizing the Article reducer
      //https://infomediacorp.atlassian.net/browse/FE-11309
      payload: {
        originalArticle,
        activeArticle,
        translate: !translated,
        forAlertView: isAlertView,
        isECBUser,
      },
    })
  }

  const shouldDisplayFooter = !editMode && !hideAllTags && !isProfileEditor
  const articleId = articleIdFromIds(id_site, id_article)

  const commentCount = (alteredArticleComments[articleId] || activeArticle?.usercomments?.count) ?? 0

  const isSocial = isSocialArticle(article)
  const isSameTranslateLanguage = activeArticle?.language?.text === translateToLanguageCode

  const showTranslateAction =
    !translateAuto &&
    (!isSameTranslateLanguage || (isSameTranslateLanguage && translated)) &&
    remainingTranslationQuota > 0

  const isEdited = editedState?.includes(articleId)

  const translationProps = globalAutoTranslateOn &&
    showTranslateAction && {
      language: translateToLanguage,
      onTranslateClick: translateArticleHandler,
      isTranslated: translated,
      reachedQuota: remainingTranslationQuota === 0,
    }

  const shouldShowToggleExpandButton = !editMode && !shouldBeExpanded && expansion === ExpansionMode.ALLOWED

  return (
    <>
      <ArticlePreviewRoot
        data-cy="article-preview-root"
        className={cn(
          'mt-0',
          {
            'mb-0 border-none': isAchiveLayout || articleModal?.status,
            'pb-7': !shouldDisplayFooter,
            'pt-6': index === 0,
            'm-0 border-none': isEditModalOpen && index === 0,
          },
          className,
        )}
      >
        <ArticleInnerWrapper articleIndex={index} onActiveArticleChange={onActiveArticleChange}>
          {isSocial ? (
            <PreviewSocial
              hideActionButtons={hideActionButtons}
              allowExpanded={allowExpanded}
              article={activeArticle}
              editable={editable}
              editMode={editMode}
              expanded={expanded}
              onContentChange={onContentChange}
              translation={translationProps}
              openModal={openModal}
              isEdited={isEdited}
              // @ts-expect-error: Muted so we could enable TS strict mode
              socialMeta={activeArticle?.social_meta}
              index={index}
              shouldBeExpanded={shouldBeExpanded}
              isAlert={isAlertView}
            />
          ) : (
            <PreviewArticle
              hideActionButtons={hideActionButtons}
              article={activeArticle}
              editable={editable}
              editMode={editMode}
              expanded={expanded}
              onContentChange={onContentChange}
              translation={translationProps}
              openModal={openModal}
              isEdited={isEdited}
              index={index}
              shouldBeExpanded={shouldBeExpanded}
              isAlert={isAlertView}
            />
          )}

          {!isProfileEditor && (
            <div
              className={cn('flex justify-center gap-3', {
                'pt-6': shouldShowToggleExpandButton || !!identicalDocuments.length || !isEmpty(commentGroups),
              })}
              ref={(ref) => {
                // @ts-expect-error: Muted so we could enable TS strict mode
                articlePartsRefs.current.bottomActions = ref
              }}
            >
              {shouldShowToggleExpandButton && (
                <Button variant="outline" onClick={toggleExpanded}>
                  {!expanded ? <T _str="Read more" /> : <T _str="Show less" />}
                </Button>
              )}

              {!!identicalDocuments.length && (
                <IdenticalArticles
                  article={originalArticle}
                  activeArticle={article}
                  identicalCount={identical}
                  isProfileEditor={isProfileEditor}
                  isAlert={isAlertView}
                />
              )}

              {!isEmpty(commentGroups) && (
                <Button variant="outline" onClick={handleOpenCommentsModal}>
                  <CommentMultiple24Regular />
                  <T _str="Comments" /> {`(${commentCount})`}
                </Button>
              )}
            </div>
          )}

          {shouldDisplayFooter && <ArticleFooter article={article} originalArticle={originalArticle} />}
          <Disclaimer />
        </ArticleInnerWrapper>
      </ArticlePreviewRoot>

      <FullscreenIFrameDialog
        open={isModalOpen}
        onHide={() => setIsModalOpen(false)}
        // @ts-expect-error: Muted so we could enable TS strict mode
        title={header.text}
        // @ts-expect-error: Muted so we could enable TS strict mode
        src={getArticleUrl({
          url,
          content_protected,
          orig_url,
        })}
        displayUrl={hasPrintPdfLink ? url : undefined}
      />
    </>
  )
}

export default ArticlePreview
