import { ArrowDownload20Regular, DocumentText20Regular } from '@fluentui/react-icons'
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Loader,
  formatDate,
} from '@opoint/infomedia-storybook'
import { T, useT } from '@transifex/react'

import { Notification } from '../../../api/opoint.schemas'
import { NotificationStatus } from '../../../constants/notificationsTypes'
import { TEMPLATE_TYPES } from '../../../opoint/templates'
import { getDateVariant, getOpointLocale, hasRestrictedReportHistory } from '../../../selectors/settingsSelectors'
import SuspenseWithLoading from '../../common/Modal/SuspenseWithLoading'
import ReportActions from '../../common/ReportActions'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { useAppSelector } from '../../hooks/useAppSelector'
import { useToggle } from '../../hooks/useToggle'
import { ReportObject } from '../../types/reports'

import NotificationDeleteConfirm from './NotificationDeleteConfirm'

type Props = {
  notification: Notification
}

const getReportType = (report: ReportObject | null): string => {
  const type = report ? Object.keys(TEMPLATE_TYPES).find((key) => TEMPLATE_TYPES[key] === report.type) : undefined

  return type || 'Not Available'
}

const NotificationComponent = ({ notification }: Props) => {
  const t = useT()

  const locale = useAppSelector(getOpointLocale)
  const dateVariant = useAppSelector(getDateVariant)

  const [isModalOpen, toggleIsModalOpen] = useToggle()
  const dispatch = useAppDispatch()
  const isRestrictedReportsHistory = useAppSelector(hasRestrictedReportHistory) ?? true

  // @ts-expect-error: Muted so we could enable TS strict mode
  const type = notification.object.type
  // @ts-expect-error: Muted so we could enable TS strict mode
  const status = notification.object.value.status

  // @ts-expect-error: Muted so we could enable TS strict mode
  const timeFinished = notification.object.value.timeFinished
  const time =
    timeFinished > 0 ? (
      formatDate({ dateVariant, localeName: locale, unixTimestamp: timeFinished })
    ) : (
      <T _str="Not available" />
    )
  // @ts-expect-error: Muted so we could enable TS strict mode
  const fileType = getReportType(notification.object.value)
  // @ts-expect-error: Muted so we could enable TS strict mode
  const isExport = notification.object.type === 'export'
  const categoryDisplayed = isExport ? t('Export') : t('Report')
  // @ts-expect-error: Muted so we could enable TS strict mode
  const downloadStatus = notification.object.value.status

  const handleSendReport = () => {
    dispatch({
      type: 'SHARE_REPORT',
      payload: {
        source: 'OPENED_REPORT',
        notification,
      },
    })
  }

  return (
    <Dialog
      open={isModalOpen}
      onOpenChange={(nextState) => {
        toggleIsModalOpen(nextState)
      }}
    >
      <DialogTrigger className="flex w-full gap-x-2 px-4 py-2 text-left text-sm hover:bg-grey.7 focus:ring-0 focus-visible:bg-grey.7">
        {type === 'export' ? (
          <ArrowDownload20Regular className="shrink-0" />
        ) : (
          <DocumentText20Regular className="shrink-0" />
        )}

        <span className="flex flex-col gap-y-0.5 overflow-hidden">
          <strong className="truncate font-medium">{notification.title}</strong>

          {status === NotificationStatus.DONE && timeFinished > 0 && (
            <span className="text-sky.cloudy">
              {formatDate({ dateVariant, localeName: locale, unixTimestamp: timeFinished })}
            </span>
          )}

          {status === NotificationStatus.IN_PROGRESS && (
            <span className="flex items-center gap-x-1.5 text-sky.cloudy">
              <Loader size="tiny" />
              <T _str="In progress" />
            </span>
          )}

          {status === NotificationStatus.FAILED && (
            <span className="text-berry.2">
              <T _str="Failed" />
            </span>
          )}
        </span>
      </DialogTrigger>

      <DialogContent className="max-w-screen-sm">
        <DialogHeader>
          <DialogTitle className="mb-0">
            {notification?.title ? `${categoryDisplayed}: ${notification.title}` : categoryDisplayed}
          </DialogTitle>
        </DialogHeader>
        <DialogBody className="flex flex-col items-center gap-y-4 py-4">
          {downloadStatus === NotificationStatus.DONE && (
            <SuspenseWithLoading>
              <ReportActions
                // @ts-expect-error: Muted so we could enable TS strict mode
                reportOpened={notification.object.value}
                sendReport={handleSendReport}
                isRestrictedReportsHistory={isRestrictedReportsHistory}
              />
            </SuspenseWithLoading>
          )}

          {downloadStatus === NotificationStatus.IN_PROGRESS && (
            <>
              <Loader />
              <T _str="The report is being generated…" />
            </>
          )}

          {downloadStatus === NotificationStatus.FAILED && (
            <span className="text-berry.2">
              <T _str="Failed" />
            </span>
          )}
        </DialogBody>

        <DialogFooter className="flex w-full items-center !justify-between text-lg">
          <div className="flex flex-col">
            {downloadStatus === NotificationStatus.DONE && (
              <div>
                <span>
                  <T _str="Date" />:
                </span>{' '}
                {time}
              </div>
            )}

            <div>
              <span>
                <T _str="Type" />:
              </span>{' '}
              {fileType}
            </div>
          </div>

          <NotificationDeleteConfirm notification={notification} successAction={() => toggleIsModalOpen(false)} />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default NotificationComponent
