import { ChevronDown16Filled, ChevronUp16Filled, Warning20Regular } from '@fluentui/react-icons'
import { Button, Tooltip, TooltipContent, TooltipTrigger } from '@opoint/infomedia-storybook'
import { T } from '@transifex/react'
import { Row } from '@tanstack/react-table'
import { GroupRecipientChild } from '../../../api/opoint.schemas'
import { RecipientNameCellData, RecipientWithGroupStatus } from './types'

type RecipientNameProps = {
  recipientData: RecipientNameCellData
  row: Row<RecipientWithGroupStatus | GroupRecipientChild>
}

const RecipientName = ({ recipientData, row }: RecipientNameProps) => (
  <div className="flex pl-6">
    <div className="my-auto flex h-full flex-col text-label-l">
      {recipientData.name ? (
        <>
          {recipientData.name && <span>{recipientData.name}</span>}
          {recipientData.email && <span className="text-label-s text-grey.3">{recipientData.email}</span>}
        </>
      ) : (
        recipientData.email
      )}
    </div>
    {row.getCanExpand() && (
      <Button variant="text" size="icon" onClick={() => row.toggleExpanded()}>
        {row.getIsExpanded() ? <ChevronUp16Filled /> : <ChevronDown16Filled />}
      </Button>
    )}
    {!recipientData.inPreviousSending && (
      <Tooltip>
        <TooltipTrigger>
          <Warning20Regular className="ml-2 text-sand.3" />
        </TooltipTrigger>
        <TooltipContent>
          <T _str="This recipient was not present in the recipient list at the time of sending." />
        </TooltipContent>
      </Tooltip>
    )}
  </div>
)

export default RecipientName
