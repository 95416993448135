import { Dialog, DialogBody, DialogContent, DialogHeader, DialogTitle } from '@opoint/infomedia-storybook'
import { useT } from '@transifex/react'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { getDashboardModalState } from '../../../../../selectors/uiSelectors'
import { useAppSelector } from '../../../../hooks/useAppSelector.ts'

const DashboardModal = () => {
  const t = useT()
  const dispatch = useAppDispatch()

  const handleClose = () =>
    dispatch({ type: 'TOGGLE_DASHBOARD_MODAL', payload: { url: null, name: null, isOpen: false } })

  const { url, name, isOpen } = useAppSelector(getDashboardModalState)
  const dashboardTitle = name ? t('Dashboard: {name}', { name }) : t('Dashboard')

  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      <DialogContent variant="fullscreen">
        <DialogHeader>
          <DialogTitle>{dashboardTitle}</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <iframe
            // @ts-expect-error: Muted so we could enable TS strict mode
            id={name}
            className="h-full w-full"
            tabIndex={0}
            // @ts-expect-error: Muted so we could enable TS strict mode
            src={url}
            title={dashboardTitle}
            referrerPolicy="no-referrer"
          />
        </DialogBody>
      </DialogContent>
    </Dialog>
  )
}

export default DashboardModal
