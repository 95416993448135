import { cn, TagColor } from '@opoint/infomedia-storybook'
import { useT } from '@transifex/react'
import { forwardRef, MouseEvent } from 'react'

import { People16Filled } from '@fluentui/react-icons'
import { isTag, isTagSelected, mapColorToStyle } from '../../helpers/tags'
import { getTaggedIdenticalArticlesCount } from '../../opoint/tags'
import { getLastUsedTag } from '../../selectors/tagsComposedSelectors'
import { useAppDispatch } from '../hooks/useAppDispatch'
import { useAppSelector } from '../hooks/useAppSelector'
import type { ChildAlertTag, Tag } from '../types/tag'
import { M360Article } from '../../opoint/articles/types'
import { getFoldersTree } from '../../selectors/foldersSelectors'

type KeywordTagComponentProps = {
  article: M360Article
  tag: Tag | ChildAlertTag
  hideWeightControls?: boolean
  isOverflow?: boolean
  setIdenticalArticleTag?: () => void
  handleUpdateTagWeight?: (increment: boolean) => void
}

type KeywordTagAction = { tag: Tag | ChildAlertTag; articles: M360Article[] }

const KeywordTag = forwardRef<HTMLDivElement, KeywordTagComponentProps>(
  (
    { article, tag, hideWeightControls = false, isOverflow = false, setIdenticalArticleTag, handleUpdateTagWeight },
    ref,
  ) => {
    const appDispatch = useAppDispatch()
    const t = useT()
    const lastUsedTag = useAppSelector(getLastUsedTag)
    const folders = useAppSelector(getFoldersTree)
    const isIdentical = lastUsedTag?.id === tag.id

    const incrementTag: ({ tag, articles }: KeywordTagAction) => void = (payload) =>
      appDispatch({ type: 'INCREMENT_TAG_WEIGHT_FOR_ARTICLE', payload })
    const setTag: ({ tag, articles }: KeywordTagAction) => void = (payload) =>
      appDispatch({ type: 'TOGGLE_TAG_ARTICLE', payload })
    const setActiveTag: ({ tag }: { tag: Tag }) => void = (payload) =>
      appDispatch({ type: 'SET_LAST_USED_TAG_ID', payload })

    const onIncrementClickHandler = (e: MouseEvent<HTMLButtonElement>) => {
      if (isIdentical && setIdenticalArticleTag !== undefined) {
        e.stopPropagation()
        // @ts-expect-error: Muted so we could enable TS strict mode
        handleUpdateTagWeight(true)

        return
      }
      incrementTag({ tag, articles: [article] })
    }

    const onSetClickHandler = (e: MouseEvent<HTMLButtonElement>) => {
      if (isIdentical && setIdenticalArticleTag !== undefined) {
        e.stopPropagation()
        setIdenticalArticleTag()

        return
      }
      setTag({ tag, articles: [article] })
    }

    const setActiveTagHandler = () => {
      isTag(tag) && setActiveTag({ tag })
    }

    const isSelected = isTagSelected(tag, article.tags)
    const identicals = getTaggedIdenticalArticlesCount(article.tags, tag, article.identical_documents)
    const isNumber = isSelected && !hideWeightControls
    const weight = article.tags?.[tag.id]?.weight ?? 1
    const tagColor = isTag(tag) && mapColorToStyle(tag.color as TagColor)

    const parentFolder = folders.find(f => f.children?.some(t => t.id === tag.id))
    const isSharedTag = !!(parentFolder?.category && parentFolder.category > 0)

    if (isOverflow) {
      return (
        <div ref={ref} className="flex w-full hover:bg-grey.7">
          <button
            type="button"
            onClick={onSetClickHandler}
            className={cn(
              'flex min-h-10 grow cursor-pointer items-center gap-2  overflow-hidden break-all px-4 py-2 text-left text-label-l focus-visible:bg-grey.7 focus-visible:ring-0',
              {
                'font-medium pr-4': isNumber,
              },
            )}
          >
            <span className="truncate">{tag.name}</span>
            {isNumber && isTag(tag) && (
              <span
                className={cn(
                  'size-s shrink-0 rounded-full',
                  mapColorToStyle(tag.color as TagColor),
                )}
              />
            )}
            {identicals && (
              <button type="button" className="border-none" onClick={setActiveTagHandler}>
                {identicals}
              </button>
            )}
          </button>

          {isNumber && (
            <button
              type="button"
              className="my-auto mr-4 size-6 shrink-0 rounded bg-grey.7 text-label-l"
              onClick={onIncrementClickHandler}
            >
              <span className="sr-only">{t('Increment tag weight')}</span>
              {weight}
            </button>
          )}
        </div>
      )
    }

    return (
      <div
        ref={ref}
        className={cn(
          'flex h-l cursor-pointer items-center justify-between rounded bg-grey.7 text-label-l',
          isNumber ? `${tagColor} text-white` : '',
        )}
      >
        {isSharedTag && <div className='pl-2.5'>
          <People16Filled title={t('Shared')} />
        </div>}

        <button
          type="button"
          onClick={onSetClickHandler}
          className={cn('h-full overflow-hidden truncate px-3', {
            'pr-2.5': isNumber,
            'truncate max-w-[15rem]': isIdentical,
            'pl-1.5': isSharedTag,
          })}
        >
          {tag.name}
          {identicals && <span className="ml-2">{identicals}</span>}
        </button>

        {isNumber && (
          <button
            type="button"
            className="inline-block h-full border-l border-white/40 pl-2.5 pr-3 text-center"
            onClick={onIncrementClickHandler}
          >
            <span className="sr-only">{t('Increment tag weight')}</span>
            {weight}
          </button>
        )}
      </div>
    )
  },
)

KeywordTag.displayName = 'KeywordTag'

export default KeywordTag
