import { Add12Filled, Delete12Regular, Dismiss12Filled, Info20Regular, Subtract12Filled } from '@fluentui/react-icons'
import { cn } from '@opoint/infomedia-storybook'
import { useT } from '@transifex/react'

import { MouseEvent } from 'react'
import { Filter } from '../../../opoint/flow'
import { filterIsDetailsSupported, isNotInvertable } from '../../../opoint/search'
import { getStatisticsList } from '../../../selectors/statisticsSelectors'
import { getGlobalTrashTag } from '../../../selectors/trashTagsSelectors'
import { useAppSelector } from '../../hooks/useAppSelector'
import { SearchFilterKey } from '../../hooks/useSearchFilters'
import { UmamiEvent } from '../../../helpers/umami.ts'

type FilterPillProps = {
  filter: Filter
  hasSeparator?: boolean
  deleteHandler?: () => void
  invertHandler?: () => void
  onClickHandler?: () => void
  onFilterClickHandler?: () => void
  showInfoIcon?: boolean
  invalid?: boolean
}

const FilterPill = ({
  filter,
  deleteHandler,
  invertHandler,
  onClickHandler,
  onFilterClickHandler,
  showInfoIcon = true,
  invalid = false,
}: FilterPillProps): JSX.Element | null => {
  const t = useT()
  const statistics = useAppSelector(getStatisticsList)
  const nonInvertable = isNotInvertable(filter)
  const globalTrashTag = useAppSelector(getGlobalTrashTag)

  const isTrash = filter.type === SearchFilterKey.TRASH
  const isGlobalTrash = isTrash && Number(filter.id) === globalTrashTag?.id

  const detailsPreviewSupported = () => filterIsDetailsSupported(filter)

  const isInverted = !!filter.type && !!filter.type.length && filter.type.startsWith('-')

  const name = ((): string | undefined => {
    if (isGlobalTrash) {
      return t('General trash')
    }

    if (filter.type === SearchFilterKey.CHART) {
      return statistics?.find((item) => item.id === Number(filter.id))?.name ?? ''
    }

    return filter.name
  })()

  const isInvertable = invertHandler && !nonInvertable
  const isDetailsPreviewSupported = !!filter.type && detailsPreviewSupported()
  const showInfo = onClickHandler && showInfoIcon && isDetailsPreviewSupported

  const handleFilterClick = (e: MouseEvent) => {
    e.stopPropagation()
    onFilterClickHandler?.()
  }

  if (!name) {
    return <div className="h-6 w-20 animate-pulse rounded-[0.188rem] bg-grey.7" />
  }

  return (
    <div
      title={name}
      className={cn(
        'group/item relative flex h-6 min-w-[3rem] items-center justify-between rounded-[0.188rem] bg-sky.1 p-1.5 px-2 text-sm text-white hover:text-white/60',
        { 'bg-white text-berry.2 border border-berry.2 hover:text-berry.2': invalid, 'min-w-[4.25rem]': showInfo },
      )}
      onClick={handleFilterClick}
    >
      <div className="pointer-events-none absolute inset-0 flex items-center justify-between px-0.5">
        <span>
          {!invalid && isInvertable && (
            <button
              type="button"
              title={isInverted ? t('Include') : t('Exclude')}
              className="pointer-events-auto flex justify-center rounded-sm opacity-0 hover:text-sky.1 focus:opacity-100 focus:outline-none focus-visible:ring-1 focus-visible:ring-sky.6 group-focus-within/item:bg-sky.cloudy group-hover/item:bg-sky.cloudy group-hover/item:opacity-100 group-hover/item:hover:bg-white"
              onClick={invertHandler}
              onKeyDown={(e) => {
                if (e.key === ' ') {
                  invertHandler()
                }
              }}
              data-umami-event={
                isInverted ? UmamiEvent.SEARCH_FILTER_PILL_INCLUDED : UmamiEvent.SEARCH_FILTER_PILL_EXCLUDED
              }
              data-umami-event-filter-type={filter.type}
              data-umami-event-filter-id={filter.id}
            >
              {isInverted ? (
                <Add12Filled className="h-5 w-5 rounded-sm bg-sky.cloudy p-[3px] hover:bg-white" />
              ) : (
                <Subtract12Filled className="h-5 w-5 rounded-sm bg-sky.cloudy p-[1px] hover:bg-white" />
              )}
            </button>
          )}
        </span>

        <span>
          {showInfo && (
            <button
              type="button"
              title={t('Information')}
              className="pointer-events-auto flex justify-center rounded-sm opacity-0 hover:text-sky.1 focus:opacity-100 focus:outline-none focus-visible:ring-1 focus-visible:ring-sky.6 group-focus-within/item:bg-sky.cloudy group-hover/item:bg-sky.cloudy group-hover/item:opacity-100 group-hover/item:hover:bg-white"
              onClick={onClickHandler}
              onKeyDown={(e) => {
                if (e.key === ' ') {
                  onClickHandler()
                }
              }}
              data-umami-event={UmamiEvent.SEARCH_FILTER_PILL_INFO_VIEWED}
              data-umami-event-filter-type={filter.type}
              data-umami-event-filter-id={filter.id}
            >
              <Info20Regular className="h-5 w-5 rounded-sm bg-sky.cloudy hover:bg-white" />
            </button>
          )}
        </span>

        {deleteHandler && (
          <button
            type="button"
            title={t('Remove')}
            className={cn(
              'pointer-events-auto flex justify-center rounded-sm p-1 hover:text-sky.1 focus:outline-none focus-visible:bg-sky.cloudy focus-visible:ring-1 focus-visible:ring-sky.6 group-hover/item:bg-sky.cloudy group-hover/add:text-sky.1 group-hover/item:hover:bg-white ',
              { 'group-hover/item:bg-white hover:text-berry.2': invalid },
            )}
            onClick={deleteHandler}
            onKeyDown={(e) => {
              if (e.key === ' ') {
                deleteHandler()
              }
            }}
            data-umami-event={UmamiEvent.SEARCH_FILTER_PILL_REMOVED}
            data-umami-event-filter-type={filter.type}
            data-umami-event-filter-id={filter.id}
          >
            <Dismiss12Filled />
          </button>
        )}
      </div>

      <div
        className={cn({
          'flex items-center pr-5 gap-x-1.5 leading-none overflow-x-hidden': deleteHandler,
        })}
      >
        {isTrash && <Delete12Regular aria-hidden={true} />}

        <span className="truncate">{isInverted ? <s>{name}</s> : name}</span>
      </div>
    </div>
  )
}

export default FilterPill
