import { Label, Switch } from '@opoint/infomedia-storybook'
import { T } from '@transifex/react'

import { getProfiles } from '../../../../../selectors/profilesSelectors'
import { getUISetting } from '../../../../../selectors/settingsSelectors'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { useAppSelector } from '../../../../hooks/useAppSelector'

export const MostUsedProfilesToggle = () => {
  const dispatch = useAppDispatch()

  const profiles = useAppSelector(getProfiles)
  const displayImportantProfiles = useAppSelector(getUISetting('DISPLAY_IMPORTANT_PROFILES'))

  const handleToggleMostUsed = () => {
    dispatch({
      type: 'SETTINGS_SAVE',
      payload: {
        settings: {},
        uiSettings: { DISPLAY_IMPORTANT_PROFILES: !displayImportantProfiles },
        toggleSetting: true,
      },
    })
  }

  if (profiles.length < 15) {
    return null
  }

  return (
    <div className="m-4 flex items-center justify-between rounded border border-grey.6 p-3">
      <Label htmlFor="ProfilesNavigationSection-MostUsedToggle" className="!text-label-s !font-bold">
        <T _str="Show only most used profiles" />
      </Label>
      <Switch
        id="ProfilesNavigationSection-MostUsedToggle"
        checked={displayImportantProfiles}
        onCheckedChange={handleToggleMostUsed}
      />
    </div>
  )
}
