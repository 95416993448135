import { Add12Filled, Subtract12Filled } from '@fluentui/react-icons'
import { TagColor, cn } from '@opoint/infomedia-storybook'
import { useT } from '@transifex/react'
import { MouseEvent, forwardRef } from 'react'

import { isTag } from '../../guards/tags'
import { isTagSelected, mapColorToStyle } from '../../helpers/tags'
import { getLastUsedTag } from '../../selectors/tagsComposedSelectors'
import { useAppDispatch } from '../hooks/useAppDispatch'
import { useAppSelector } from '../hooks/useAppSelector'
import type { Tag } from '../types/tag'
import { M360Article } from '../../opoint/articles/types'

type MentometerTagAction = { tag: Tag; articles: M360Article[] }

type MentometerTagComponentProps = {
  article: M360Article
  tag: Tag
  hideWeightControls?: boolean
  isOverflow?: boolean
  setIdenticalArticleTag?: () => void
  handleUpdateTagWeight?: (increment: boolean) => void
}

const MentometerTag = forwardRef<HTMLDivElement, MentometerTagComponentProps>(
  (
    { article, tag, hideWeightControls = false, isOverflow = false, setIdenticalArticleTag, handleUpdateTagWeight },
    ref,
  ) => {
    const appDispatch = useAppDispatch()
    const t = useT()
    const weight = article && article.tags?.[tag.id] ? article.tags?.[tag.id].weight : 0
    const isSelected = isTagSelected(tag, article.tags)
    const lastUsedTag = useAppSelector(getLastUsedTag)

    const incrementTag: ({ tag, articles }: MentometerTagAction) => void = (payload) =>
      appDispatch({ type: 'INCREMENT_TAG_WEIGHT_FOR_ARTICLE', payload })
    const decrementTag: ({ tag, articles }: MentometerTagAction) => void = (payload) =>
      appDispatch({ type: 'DECREMENT_TAG_WEIGHT_FOR_ARTICLE', payload })
    const setTag: ({ tag, articles }: MentometerTagAction) => void = (payload) =>
      appDispatch({ type: 'TOGGLE_TAG_ARTICLE', payload })

    const onDecrementClickHandler = (e: MouseEvent<HTMLButtonElement>) => {
      if (lastUsedTag?.id === tag.id && setIdenticalArticleTag !== undefined) {
        e.stopPropagation()
        // @ts-expect-error: Muted so we could enable TS strict mode
        handleUpdateTagWeight(false)

        return
      }

      if (isSelected || isOverflow) {
        decrementTag({ tag, articles: [article] })
      }
    }

    const onIncrementClickHandler = (e: MouseEvent<HTMLButtonElement>) => {
      if (lastUsedTag?.id === tag.id && setIdenticalArticleTag !== undefined) {
        e.stopPropagation()
        // @ts-expect-error: Muted so we could enable TS strict mode
        handleUpdateTagWeight(true)

        return
      }
      incrementTag({ tag, articles: [article] })
    }

    const onSetClickHandler = (e: MouseEvent<HTMLButtonElement>) => {
      if (lastUsedTag?.id === tag.id && setIdenticalArticleTag !== undefined) {
        e.stopPropagation()
        setIdenticalArticleTag()

        return
      }
      setTag({ tag, articles: [article] })
    }

    const tagColor = isTag(tag) && mapColorToStyle(tag.color as TagColor)

    if (isOverflow) {
      return (
        <div ref={ref} className="flex w-full hover:bg-grey.7">
          <button
            type="button"
            className={cn(
              'flex min-h-[2.5rem] grow cursor-pointer items-center gap-2 overflow-hidden break-all px-4 py-2 text-left text-label-l focus-visible:bg-grey.7 focus-visible:ring-0',
              {
                'font-medium pr-4': isSelected,
              },
            )}
            onClick={onSetClickHandler}
          >
            <span className="truncate"> {tag.name}</span>

            {isSelected && <span className={cn('h-[1.125rem] w-[1.125rem] shrink-0 rounded-full', tagColor)} />}
          </button>
          {isSelected && (
            <div className="inset-y-0 my-auto mr-4 flex h-6 shrink-0 items-center gap-3 rounded bg-grey.7 p-3 text-label-l">
              <button type="button" onClick={onDecrementClickHandler}>
                <span className="sr-only">{t('Decrement tag weight')}</span>
                <Subtract12Filled />
              </button>
              <div className="w-5 text-center">{weight}</div>
              <button type="button" onClick={onIncrementClickHandler}>
                <span className="sr-only">{t('Increment tag weight')}</span>
                <Add12Filled />
              </button>
            </div>
          )}
        </div>
      )
    }

    return (
      <div ref={ref}>
        <div
          className={cn(
            'flex h-[1.875rem] cursor-pointer items-center justify-between rounded bg-grey.7 text-label-l',
            isSelected ? `${tagColor} text-white` : '',
          )}
        >
          {!hideWeightControls && (
            <button
              type="button"
              className={cn('flex h-7 items-center border-r border-grey.5 pl-[0.6875rem] pr-[0.5625rem] text-center', {
                'border-white/40': isSelected,
              })}
              onClick={onDecrementClickHandler}
            >
              <span className="sr-only">{t('Decrement tag weight')}</span>
              <Subtract12Filled />
            </button>
          )}

          <button type="button" className="overflow-hidden truncate px-2.5" onClick={onSetClickHandler}>
            <span>
              {tag.name} ({weight})
            </span>
          </button>

          {!hideWeightControls && (
            <button
              type="button"
              className={cn('h-7 border-l border-grey.5 pl-2.5 pr-3 text-center text-base', {
                'border-white/40': isSelected,
              })}
              onClick={onIncrementClickHandler}
            >
              <span className="sr-only">{t('Increment tag weight')}</span>
              <Add12Filled />
            </button>
          )}
        </div>
      </div>
    )
  },
)
MentometerTag.displayName = 'MentometerTag'

export default MentometerTag
