import { Dialog, DialogBody, DialogContent, DialogHeader, DialogTitle } from '@opoint/infomedia-storybook'
import { T } from '@transifex/react'

import { lazy } from '../../../helpers/lazy'
import { isEditorModalOpen } from '../../../selectors/uiSelectors'
import SuspenseWithLoading from '../../common/Modal/SuspenseWithLoading'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { useAppSelector } from '../../hooks/useAppSelector'

import styles from './ModuleWrapper.module.scss'

const FormWrapper = lazy(() => import('./FormWrapper'))

const TemplateEditorDialog = () => {
  const dispatch = useAppDispatch()
  const isModalOpen = useAppSelector(isEditorModalOpen)

  const onOpenChange = () => {
    isModalOpen ? dispatch({ type: 'TEMPLATE_EDITOR_MODAL_CLOSE' }) : dispatch({ type: 'TEMPLATE_EDITOR_MODAL_OPEN' })
  }

  return (
    <Dialog open={isModalOpen} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            <T _str="Template editor" />
          </DialogTitle>
        </DialogHeader>
        <DialogBody>
          <SuspenseWithLoading>
            <div className={styles.modalSettings}>
              <FormWrapper />
            </div>
          </SuspenseWithLoading>
        </DialogBody>
      </DialogContent>
    </Dialog>
  )
}

export default TemplateEditorDialog
