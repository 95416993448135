import { CheckmarkCircle24Filled, ChevronLeft24Regular, ErrorCircle24Filled } from '@fluentui/react-icons'
import { Button, Loader } from '@opoint/infomedia-storybook'
import { T, useT } from '@transifex/react'
import { useCallback, useEffect, useState } from 'react'

import { reportsActionStep } from '../../../opoint/reports'
import { TEMPLATE_TYPES, TEMPLATE_TYPES_ONLY_FOR_DOWNLOAD } from '../../../opoint/templates'
import { getOpenedReport, getShareData } from '../../../selectors/reportsSelector'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { useAppSelector } from '../../hooks/useAppSelector'
import { ContactsPath } from '../../types/contact'
import { ReportObject } from '../../types/reports'
import FullscreenIFrameDialog from '../FullscreenIFrameDialog'
import ShareContentInput from '../ShareContentInput'

interface ReportActionsProps {
  onReportsModalClose?: () => void
  reportOpened: ReportObject
  sendReport: () => void
  reportCreate?: boolean
  title?: string
  isRestrictedReportsHistory?: boolean
}

const ReportActions = ({
  reportOpened,
  sendReport,
  reportCreate,
  title,
  isRestrictedReportsHistory = true,
  onReportsModalClose,
}: ReportActionsProps) => {
  const t = useT()
  const dispatch = useAppDispatch()

  const [isArticleModalOpen, setIsArticleModalOpen] = useState(false)
  const { shareReportMessage, attachment: isAttachmentChecked, stepNumber: step } = useAppSelector(getShareData) || {}
  const { exportObject } = useAppSelector(getOpenedReport) || {}
  const isExport = !!exportObject

  const handleUpdateStep = useCallback((payload) => dispatch({ type: 'SHARE_REPORT_UPDATE_STEP', payload }), [dispatch])

  const handleToggleAttachment = () => dispatch({ type: 'SHARE_REPORT_TOGGLE_ATTACHMENT' })

  useEffect(() => {
    handleUpdateStep({ stepNumber: reportsActionStep.ACTION_SELECTION })

    return () => {
      dispatch({ type: 'CLEAR_SHARE_REPORT_RECIPIENTS' })
      dispatch({ type: 'CLEAR_SHARE_REPORT_DATA' })
    }
  }, [dispatch, handleUpdateStep])

  const onShareClickHandler = () => {
    handleUpdateStep({ stepNumber: reportsActionStep.SHARE_ACTION })
  }

  const onDownloadClickHandler = () => {
    if (!reportOpened?.download) {
      return
    }

    const target =
      !reportOpened || reportOpened.type === TEMPLATE_TYPES.PDF || reportOpened.type === TEMPLATE_TYPES.HTML
        ? '_blank'
        : '_self'

    window.open(reportOpened.download, target)
  }

  const handleMessageChange = (e) => {
    dispatch({ type: 'SHARE_REPORT_CHANGE_MESSAGE', payload: { message: e.target.value } })
  }

  const backToActions = () => {
    handleUpdateStep({ stepNumber: reportsActionStep.ACTION_SELECTION })
  }

  const closeReports = () => {
    if (onReportsModalClose) {
      onReportsModalClose()
    }
  }

  const isShareSuccessOrFailure =
    step === reportsActionStep.SHARE_REPORT_SUCCESS || step === reportsActionStep.SHARE_REPORT_FAILURE

  const renderDone = () => (
    <>
      {step === reportsActionStep.SHARING_REPORT && <Loader />}
      {step === reportsActionStep.SHARE_REPORT_SUCCESS && (
        <div className="flex flex-col items-center gap-y-4">
          <CheckmarkCircle24Filled className="size-8 text-spring.1" />
          <h4>
            <T _str="Your report was successfully shared." />
          </h4>
        </div>
      )}

      {step === reportsActionStep.SHARE_REPORT_FAILURE && (
        <div className="flex flex-col items-center gap-y-4">
          <ErrorCircle24Filled className="size-8 text-berry.1" />
          <h4>
            <T _str="Error occurred, please try again later." />
          </h4>
        </div>
      )}

      {isShareSuccessOrFailure && (
        <div className="flex items-center justify-center gap-x-2">
          <Button variant="outline" size="icon" onClick={backToActions}>
            <ChevronLeft24Regular />
            <span className="sr-only">{t('Back')}</span>
          </Button>

          {reportCreate && (
            <Button onClick={closeReports}>
              <T _str="Close" />
            </Button>
          )}
        </div>
      )}
    </>
  )

  const isShareActive = step === reportsActionStep.SHARE_ACTION
  const isShareDone = isShareSuccessOrFailure || step === reportsActionStep.SHARING_REPORT

  const category = isExport ? t('export') : t('report')

  // Currently, it is not possible to display proprietary formats such as DOC, PPT, and XLS in a browser.
  const isDisabledIFrameDialog = Object.values(TEMPLATE_TYPES_ONLY_FOR_DOWNLOAD).includes(reportOpened.type)

  return (
    <div className="flex w-full flex-col items-center gap-y-4">
      <h3 className="text-center text-heading-3 font-bold">
        {isShareActive || isShareDone || !title
          ? !isRestrictedReportsHistory
            ? t('Share {category}', { category })
            : t('Open {category}', { category })
          : title}
      </h3>

      {!isRestrictedReportsHistory ? (
        <>
          {!isShareActive && !isShareDone && (
            <div className="flex items-center justify-center gap-x-2">
              <Button variant="outline" onClick={onDownloadClickHandler}>
                <T _str="Download" />
              </Button>

              <Button onClick={onShareClickHandler}>
                <T _str="Share" />
              </Button>
            </div>
          )}

          {isShareActive && !isShareDone && (
            <ShareContentInput
              shareMessage={shareReportMessage}
              updateStep={handleUpdateStep}
              handleMessageChange={handleMessageChange}
              handleToggleAttachment={handleToggleAttachment}
              isAttachmentChecked={isAttachmentChecked}
              sendReport={sendReport}
              formName={ContactsPath.ShareReportContacts}
              simpleForm={false}
            />
          )}
        </>
      ) : (
        <div className="flex items-center justify-center">
          {isDisabledIFrameDialog ? (
            <Button onClick={onDownloadClickHandler}>
              <T _str="Download" />
            </Button>
          ) : (
            <>
              <Button onClick={() => setIsArticleModalOpen(true)}>
                <T _str="Open" />
              </Button>

              <FullscreenIFrameDialog
                open={isArticleModalOpen}
                onHide={() => setIsArticleModalOpen(false)}
                title={t('Report preview')}
                src={reportOpened.download}
              />
            </>
          )}
        </div>
      )}

      {isShareDone && renderDone()}
    </div>
  )
}

export default ReportActions
