import { WarningRegular } from '@fluentui/react-icons'
import { T, useT } from '@transifex/react'
import { Button } from '@opoint/infomedia-storybook'
import { useNavigate } from 'react-router-dom'
import ErrorPage from '../../components/error/ErrorPage.tsx'

export const AccessDeniedErrorPage = () => {
  const t = useT()
  const navigate = useNavigate()

  return (
    <div className="flex h-full w-full grow flex-col items-center justify-center">
      <ErrorPage
        primaryText={t('Access denied')}
        secondaryText={t(
          "You don't have permission to view this page. If you think this is a mistake, please contact support for help.",
        )}
        statusCode={403}
        icon={WarningRegular}
      >
        <Button onClick={() => navigate('/')}>
          <T _str="Back to homepage" />
        </Button>
      </ErrorPage>
    </div>
  )
}
