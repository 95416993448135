import { useT } from '@transifex/react'

import Joyride, { CallBackProps, Props, STATUS, Step } from 'react-joyride'

import { useLocalStorage } from '../hooks/useLocalStorage'

import ProductTourTooltip from './ProductTourTooltip'

interface ProductTourProps extends Omit<Props, 'run'> {
  /**
   * The key used to store the tour state in local storage.
   */
  tourKey: string

  /**
   * Array of tour steps.
   */
  steps: Step[]

  /**
   * Whether to show the progress indicator during the tour.
   * @default true
   */
  showProgress?: boolean

  /**
   * Whether to show the skip button during the tour.
   * @default true
   */
  showSkipButton?: boolean

  /**
   * Whether the tour should be played sequentially with the Next button.
   * @default true
   */
  continuous?: boolean

  /**
   * Whether to close the tooltip, when the ESC key is pressed.
   * @default true
   */
  disableCloseOnEsc?: boolean

  /**
   * Whether to close the tooltip, when the overlay is clicked.
   * @default true
   */
  disableOverlayClose?: boolean
}

const ProductTour = ({
  tourKey,
  steps,
  showProgress = true,
  showSkipButton = true,
  continuous = true,
  disableCloseOnEsc = true,
  disableOverlayClose = true,
  ...props
}: ProductTourProps) => {
  const t = useT()
  // We'll keep local storage for some users, but for others, a backend solution is preferred
  // TODO: Implement a BE solution for other customers.
  const [runTour, setRunTour] = useLocalStorage(tourKey, true)

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data

    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED]

    if (finishedStatuses.includes(status)) {
      setRunTour(false)
    }
  }

  // If the tour has already run, and the user clears the local storage, the overlay/spotlight will be missing.
  // The below check fixes it, by not rendering the tour, when it's over.
  if (!runTour) {
    return null
  }

  return (
    <Joyride
      callback={handleJoyrideCallback}
      locale={{
        back: false,
        last: false,
        next: false,
        skip: t('Skip'),
      }}
      run={runTour}
      steps={steps}
      styles={{
        options: {
          zIndex: 10000,
        },
      }}
      tooltipComponent={ProductTourTooltip}
      continuous={continuous}
      disableCloseOnEsc={disableCloseOnEsc}
      disableOverlayClose={disableOverlayClose}
      showProgress={showProgress}
      showSkipButton={showSkipButton}
      {...props}
    />
  )
}

export default ProductTour
