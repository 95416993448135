import { useT } from '@transifex/react'
import React, { useState } from 'react'

import { Checkbox, FormItem, Label, toast } from '@opoint/infomedia-storybook'
import classNames from 'classnames'
import { FolderTraitName, folderTraits } from '../../../../constants/folders'
import useGetFoldersByTrait from '../../../hooks/useGetFoldersByTrait'
import useIsECBUser from '../../../hooks/useIsECBUser'
import type { Tag } from '../../../types/tag'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { useTagsArticlesTagCreate, useTagsArticlesUntagCreate } from '../../../../api/tags/tags'
import { M360Article } from '../../../../opoint/articles/types'
import CategorizationMultiSelect from './CategorizationMultiSelect'
import CategorizationSingleSelect from './CategorizationSingleSelect'
import { getCategorizationVisibleLists, setCategorizationVisibleLists } from './controller'

export const categorizationFolderTraitsToShow: FolderTraitName[] = [
  'categorizationAndFilters',
  'categorizationOnly',
  'special',
]

export type CategorizationFolder = {
  chosenTags: Array<Tag>
  folderId: number
  folderName: string
  folderTags: Array<Tag>
  isSingleSelect: boolean
  isVisible: boolean
}

type CategorizationProps = {
  article: M360Article
  visibleListsOption?: boolean
}

const Categorization = ({ article, visibleListsOption = false }: CategorizationProps): React.JSX.Element => {
  const t = useT()
  const dispatch = useAppDispatch()
  const isECBUser = useIsECBUser()

  const visibleLists = JSON.parse(getCategorizationVisibleLists() || '{}')
  const [visibleListsLocal, setVisibleListsLocal] = useState({ ...visibleLists })

  const specialTagFolders = useGetFoldersByTrait<Tag>({
    traitNames: categorizationFolderTraitsToShow,
    withChildren: true,
  })

  const folders: Array<CategorizationFolder> = specialTagFolders.map((folder) => {
    const isECBCategorizationAndFilters = isECBUser && folderTraits[folder?.traits] === 'categorizationAndFilters'
    const isSingleSelect = ['Ranks', 'Sentiment', 'Medievinkling']?.includes(folder.name)

    const folderChildren = folder?.children || []
    const chosenTags = folderChildren.filter((tag) => article.tags?.[tag.id])

    let isListVisible = false

    if (visibleListsOption) {
      isListVisible = visibleListsLocal[folder.id] ?? true
    }

    return {
      chosenTags,
      folderId: folder.id,
      folderName: isECBCategorizationAndFilters ? t('ECB {folderName}', { folderName: folder.name }) : folder.name,
      folderTags: folderChildren,
      isSingleSelect,
      isVisible: isListVisible,
    }
  })

  const handleError = ({ untag = false } = {}) => {
    const title = untag ? t('We were not able to untag this article') : t('We were not able to tag this article')

    toast({
      title,
      variant: 'destructive',
    })
  }

  const { mutate: tagArticle } = useTagsArticlesTagCreate()
  const handleTagArticle = ({ tag }: { tag: Tag | undefined }) => {
    if (!tag) {
      handleError()
      return
    }

    tagArticle(
      { idBasket: tag.id, data: { articles: [article], weight: 0 } },
      {
        onSuccess: () => dispatch({ type: 'CATEGORIZATION_TAG_ARTICLE', payload: { tag, articles: [article] } }),
        onError: () => handleError(),
      },
    )
  }

  const { mutate: untagArticle } = useTagsArticlesUntagCreate({
    mutation: {
      onSuccess: (_, { idBasket, data: { articles } }) => {
        dispatch({
          type: 'CATEGORIZATION_UNTAG_ARTICLE',
          payload: { tagId: idBasket, articles: articles as M360Article[] },
        })
      },
    },
  })

  const handleUntagArticle = async ({ tag }: { tag: Tag | undefined }) => {
    if (!tag) {
      handleError({ untag: true })
      return
    }
    untagArticle(
      { idBasket: tag.id, data: { articles: [article] } },
      {
        onError: () => handleError({ untag: true }),
      },
    )
  }

  return (
    <>
      {folders.map((folder) => {
        const { folderId, folderName, isSingleSelect, isVisible } = folder

        return (
          <FormItem className="mb-4" key={folderId}>
            <div className={classNames({ 'mb-2 ml-1 flex items-center space-x-2': visibleListsOption })}>
              {visibleListsOption && (
                <Checkbox
                  id={`${folderId}`}
                  onCheckedChange={(checked) => {
                    const newObject = { ...visibleLists, [folderId]: checked }
                    setVisibleListsLocal({ ...newObject })

                    setCategorizationVisibleLists(JSON.stringify(newObject))
                  }}
                  checked={isVisible}
                />
              )}

              <Label htmlFor={`categorization_select_${folderName}`}>{folderName}</Label>
            </div>

            {isSingleSelect ? (
              <CategorizationSingleSelect
                onHandleTagArticle={handleTagArticle}
                onHandleUntagArticle={handleUntagArticle}
                visibleListsOption={visibleListsOption}
                {...folder}
              />
            ) : (
              <CategorizationMultiSelect
                onHandleTagArticle={handleTagArticle}
                onHandleUntagArticle={handleUntagArticle}
                visibleListsOption={visibleListsOption}
                {...folder}
              />
            )}
          </FormItem>
        )
      })}
    </>
  )
}

export default Categorization
