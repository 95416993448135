import { t } from '@transifex/native'
import { switchMap } from 'rxjs/operators'

import { ActionsObservable } from 'redux-observable'
import {
  CreateFolderFailureAction,
  CreateFolderSuccessAction,
  DeleteFolderFailureAction,
  DeleteFolderSuccessAction,
  EditFolderFailureAction,
  EditFolderSuccessAction,
  FetchFoldersFailureAction,
  FoldersActions,
  ReorderFolderFailureAction,
  ReorderFolderSuccessAction,
} from '../../../actions/folders'
import { ErrorEpic, handlerError } from '.'

const foldersErrorTypes: ErrorEpic<
  | FetchFoldersFailureAction['type']
  | CreateFolderSuccessAction['type']
  | CreateFolderFailureAction['type']
  | EditFolderSuccessAction['type']
  | EditFolderFailureAction['type']
  | DeleteFolderSuccessAction['type']
  | DeleteFolderFailureAction['type']
  | ReorderFolderSuccessAction['type']
  | ReorderFolderFailureAction['type']
> = {
  ['FETCH_FOLDERS_FAILURE']: {
    isSuccess: false,
    message: t('We were unable to fetch folders'),
    toastId: 'FETCH_FOLDERS_FAILURE',
  },
  ['CREATE_FOLDER_SUCCESS']: {
    isSuccess: true,
    message: t('Folder was successfully created'),
    toastId: 'CREATE_FOLDER_SUCCESS',
  },
  ['CREATE_FOLDER_FAILURE']: {
    isSuccess: false,
    message: t('We were unable to create the folder'),
    toastId: 'CREATE_FOLDER_FAILURE',
  },
  ['EDIT_FOLDER_SUCCESS']: {
    isSuccess: true,
    message: t('Folder was successfully edited'),
    toastId: 'EDIT_FOLDER_SUCCESS',
  },
  ['EDIT_FOLDER_FAILURE']: {
    isSuccess: false,
    message: t('We were unable to edit the folder'),
    toastId: 'EDIT_FOLDER_FAILURE',
  },
  ['DELETE_FOLDER_SUCCESS']: {
    isSuccess: true,
    message: t('Folder was successfully deleted'),
    toastId: 'DELETE_FOLDER_SUCCESS',
  },
  ['DELETE_FOLDER_FAILURE']: {
    isSuccess: false,
    message: t('We were unable to delete the folder'),
    toastId: 'DELETE_FOLDER_FAILURE',
  },
  ['REORDER_FOLDER_SUCCESS']: {
    isSuccess: true,
    message: t('Folder was successfully reordered'),
    toastId: 'REORDER_FOLDER_SUCCESS',
  },
  ['REORDER_FOLDER_FAILURE']: {
    isSuccess: false,
    message: t('We were unable to reorder the folder'),
    toastId: 'REORDER_FOLDER_FAILURE',
  },
}

const foldersErrorEpic: (action: ActionsObservable<FoldersActions>) => void = (action$) =>
  action$.pipe(switchMap(({ type }) => handlerError(foldersErrorTypes, type)))

export default foldersErrorEpic
