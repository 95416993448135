import { useT } from '@transifex/react'

import { ActionBarRightItem } from '../types'
import useIsUserPermitted from '../../../hooks/useIsUserPermitted'
import { MODULES } from '../../../constants/permissions'
import AlertCancelAction from './actions/AlertCancelAction'
import AlertSaveAction from './actions/AlertSaveAction'

export const useNewAlertActions = (): ActionBarRightItem[] => {
  const t = useT()

  const hasAlertPermission = useIsUserPermitted({
    module: 'ALERT_MODULE',
    permissions: [MODULES.ALERT_MODULE.ON],
  })

  if (!hasAlertPermission) {
    return []
  }

  return [
    {
      name: t('Cancel'),
      Action: AlertCancelAction,
    },
    {
      name: t('Save'),
      Action: AlertSaveAction,
    },
  ]
}
