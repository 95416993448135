import { Button, cn } from '@opoint/infomedia-storybook'
import { PropsWithChildren } from 'react'
import { T } from '@transifex/react'
import imgUrl from '../../images/errorRobot.svg'

type ErrorWithRetryProps = PropsWithChildren<{
  primaryText: string
  secondaryText: string
  className?: string
  retry: () => void
}>

const ErrorWithRetry = ({ primaryText, secondaryText, className, retry }: ErrorWithRetryProps) => (
  <div className={cn('flex w-full flex-col items-center justify-center bg-sky.9 py-6', className)}>
    <div className="flex max-w-[30rem] flex-col items-center justify-center gap-6 text-center">
      <div className="flex items-center justify-center rounded-full bg-grey.8">
        <img src={imgUrl} width={101} height={132} alt="Error robot image" />
      </div>
      <div>
        <p className="mb-3 text-base font-medium text-sky.1">{primaryText}</p>
        <p className="mb-0 text-sm text-sky.1">{secondaryText}</p>
      </div>

      <Button onClick={retry}>
        <T _str="Try again" />
      </Button>
    </div>
  </div>
)

export default ErrorWithRetry
