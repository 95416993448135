import { TriangleDown12Filled } from '@fluentui/react-icons'
import { cn } from '@opoint/infomedia-storybook'
import * as CollapsiblePrimitive from '@radix-ui/react-collapsible'
import classNames from 'classnames'
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'

import { SearchFilterKey } from '../../../hooks/useSearchFilters'
import { Folder } from '../../../types/folder'
import { ProfileWithChildren } from '../../../types/profile'

import { NavigationItemMultiple } from './NavigationItemMultiple'
import { NavigationProfileSingle } from './NavigationProfileSingle'

const NavigationFolder = forwardRef<
  ElementRef<typeof CollapsiblePrimitive.Root>,
  ComponentPropsWithoutRef<typeof CollapsiblePrimitive.Root>
>(({ children, className, ...props }, ref) => (
  <CollapsiblePrimitive.Root ref={ref} className={cn('flex flex-col', className)} {...props}>
    {children}
  </CollapsiblePrimitive.Root>
))
NavigationFolder.displayName = 'NavigationFolder'

const NavigationFolderTrigger = forwardRef<
  ElementRef<typeof CollapsiblePrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof CollapsiblePrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <CollapsiblePrimitive.Trigger
    ref={ref}
    className={classNames(
      'group flex items-center gap-x-3 px-4 py-3 text-left text-label-l font-medium text-sky.1',
      className,
    )}
    {...props}
  >
    <div className="flex h-6 w-6 shrink-0 items-center justify-center">
      <TriangleDown12Filled className="group-data-[state=closed]:-rotate-90" />
    </div>
    <span className="truncate">{children}</span>
  </CollapsiblePrimitive.Trigger>
))
NavigationFolderTrigger.displayName = 'NavigationFolderTrigger'

type NavigationFolderContentProps = {
  folder: Folder<ProfileWithChildren>
  isMultiple?: boolean
  selectedItemsIds: number[]
} & Omit<ComponentPropsWithoutRef<typeof CollapsiblePrimitive.Content>, 'asChild' | 'children'>

const NavigationFolderContent = forwardRef<
  ElementRef<typeof CollapsiblePrimitive.Content>,
  NavigationFolderContentProps
>(({ folder, selectedItemsIds, isMultiple, className, ...props }, ref) => (
  <CollapsiblePrimitive.Content ref={ref} className={className} asChild {...props}>
    <ul role="list">
      {isMultiple
        ? folder.children?.map((profile) => (
            <NavigationItemMultiple
              key={profile.id}
              item={profile}
              type={SearchFilterKey.PROFILES}
              selectedItemsIds={selectedItemsIds}
            />
          ))
        : folder.children?.map((profile) => (
            <NavigationProfileSingle key={profile.id} profile={profile} selectedProfileIds={selectedItemsIds} />
          ))}
    </ul>
  </CollapsiblePrimitive.Content>
))
NavigationFolderContent.displayName = 'NavigationFolderContent'

export { NavigationFolder, NavigationFolderTrigger, NavigationFolderContent }
