import { cn } from '@opoint/infomedia-storybook'
import { ButtonHTMLAttributes, forwardRef } from 'react'

const TopNavActionButton = forwardRef<HTMLButtonElement, ButtonHTMLAttributes<HTMLButtonElement>>(
  ({ children, className, ...restProps }, ref) => (
    <button
      ref={ref}
      type="button"
      className={cn(
        'relative flex h-10 w-10 min-w-[2.5rem] max-w-xs cursor-pointer items-center justify-center gap-x-2 rounded bg-transparent p-1 transition-colors hover:bg-grey.6 data-[state="open"]:bg-grey.6',
        className,
      )}
      {...restProps}
    >
      {children}
    </button>
  ),
)
TopNavActionButton.displayName = 'TopNavActionButton'

export default TopNavActionButton
