/**
 * Copied from: https://gist.github.com/ivanbtrujillo/005db52e4a8ef8fd1dc8f2db3b442d45
 *
 * When we build our React app for production, tools like Vite add unique hashes to filenames to manage caching.
 * These hashes update with any file changes, ensuring users always get the latest version, as a new hash means
 * the browser has to download the file anew. However, if someone's using the app during a new deployment,
 * the names of newly needed modules could change due to these updated hashes.
 *
 * If the user then accesses a new part of the app, their browser might try to load these modules with
 * the old names from the cache, leading to errors since those files aren't on the server anymore.
 **/

import { type ComponentType, lazy as loadAsLazy } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function importErrorHandler(err: string): { default: ComponentType<any> } {
  // Get the last reload time from local storage and the current time
  const timeStr = sessionStorage.getItem('last-reload')
  const time = timeStr ? Number(timeStr) : null
  const now = Date.now()

  // If the last reload time is more than 10 seconds ago, reload the page
  const shouldReload = !time || time + 10_000 < now

  if (shouldReload) {
    // eslint-disable-next-line no-console
    console.log('New version for this module found. Reloading ...')

    sessionStorage.setItem('LAST_RELOAD', String(now))
    window.location.reload()

    return { default: () => null }
  }

  // Let ErrorBoundary handle the error
  throw new Error(err)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const lazy = (factory: () => Promise<{ default: ComponentType<any> }>) => {
  return loadAsLazy(() => factory().catch(importErrorHandler))
}
