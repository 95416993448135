import { T } from '@transifex/react'
import { Button } from '@opoint/infomedia-storybook'

import imgUrl from '../../images/errorRobotAnimated.gif'
import Logo from '../../components/common/Logo'
import useIsInsiders, { useLeaveInsiders } from '../../components/hooks/useIsInsiders'

const ServiceUnavailableErrorPage = ({ statusCode }: { statusCode: number }) => {
  const isInsiders = useIsInsiders()
  const handleLeaveInsiders = useLeaveInsiders()

  return (
    <div className="flex h-full w-full flex-col justify-center">
      <div className="flex justify-center pt-16">
        <Logo />
      </div>

      <div className="flex h-full w-full flex-col items-center justify-center gap-4 text-center">
        <img src={imgUrl} width={200} alt="Error robot image" />
        <div>
          <p className={'mb-1 mt-6 text-heading-1 font-bold text-sky.1'}>
            <T _str="We are currently down" />
          </p>
          <p className="max-w-sm text-center text-sky.1">
            <T _str="Our service is currently unavailable. We are as shocked as you are, but our developers are working like crazy to resolve the issue. Please try again later." />
          </p>
        </div>
        <div>
          <p className="mb-1 text-heading-2">
            <T _str="Service unavailable" />
          </p>
          <p className="text-sm text-sky.cloudy">
            <T _str="Error code: {statusCode}" statusCode={statusCode} />
          </p>
        </div>
        {!isInsiders && (
          <Button variant="text" onClick={handleLeaveInsiders}>
            <T _str="Leave insiders" />
          </Button>
        )}
      </div>
    </div>
  )
}

export default ServiceUnavailableErrorPage
