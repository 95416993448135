import { ChevronLeft16Filled, ChevronRight16Filled } from '@fluentui/react-icons'
import { cn } from '@opoint/infomedia-storybook'
import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area'
import { useT } from '@transifex/react'
import { ReactNode, useEffect, useRef, useState } from 'react'

// update this constant in .scss
const DEFAULT_VISIBLE_CELLS = 6
const SCROLL_SPEED = 2

type HorizontalScrollProps = {
  children: ReactNode
}

const HorizontalScroll = ({ children }: HorizontalScrollProps) => {
  const t = useT()

  const scrollableAreaRef = useRef<HTMLDivElement>(null)
  const [leftHidden, setLeftHidden] = useState(true)
  const [rightHidden, setRightHidden] = useState(false)

  useEffect(() => {
    const el = scrollableAreaRef.current

    if (el !== null) {
      const offsetSize = el.clientWidth / DEFAULT_VISIBLE_CELLS
      setRightHidden(el.scrollWidth - offsetSize * 0.4 < el.clientWidth)
    }

    const onScroll = () => {
      // @ts-expect-error: Muted so we could enable TS strict mode
      const offsetSize = el.clientWidth / DEFAULT_VISIBLE_CELLS
      // @ts-expect-error: Muted so we could enable TS strict mode
      setLeftHidden(el.scrollLeft < offsetSize * 0.3)
      // @ts-expect-error: Muted so we could enable TS strict mode
      setRightHidden(el.scrollWidth - (el.scrollLeft + el.clientWidth) < offsetSize * 0.3)
    }
    scrollableAreaRef?.current?.addEventListener('scroll', onScroll)

    return () => scrollableAreaRef?.current?.removeEventListener('scroll', onScroll)
  }, [scrollableAreaRef])

  const scroll = (direction: 'left' | 'right') => () => {
    // @ts-expect-error: Muted so we could enable TS strict mode
    const offsetSize = scrollableAreaRef.current.clientWidth / DEFAULT_VISIBLE_CELLS
    // @ts-expect-error: Muted so we could enable TS strict mode
    scrollableAreaRef.current.scrollLeft +=
      direction === 'left' ? -offsetSize * SCROLL_SPEED : offsetSize * SCROLL_SPEED
  }

  return (
    <ScrollAreaPrimitive.Root className="mb-5">
      <ScrollAreaPrimitive.Viewport ref={scrollableAreaRef} className="scroll-smooth">
        <button
          type="button"
          className={cn(
            'absolute left-0 top-0 flex h-full w-10 items-center bg-gradient-to-r from-white to-transparent transition-opacity',
            {
              'opacity-0 pointer-events-none': leftHidden,
            },
          )}
          onClick={scroll('left')}
          title={t('Previous')}
        >
          <ChevronLeft16Filled />
        </button>

        <button
          type="button"
          className={cn(
            'absolute right-0 top-0 flex h-full w-10 items-center justify-end bg-gradient-to-l from-white to-transparent transition-opacity',
            {
              'opacity-0 pointer-events-none': rightHidden,
            },
          )}
          onClick={scroll('right')}
          title={t('Next')}
        >
          <ChevronRight16Filled />
        </button>

        <ul className="flex list-none space-x-6">{children}</ul>
      </ScrollAreaPrimitive.Viewport>

      <ScrollAreaPrimitive.Scrollbar orientation="horizontal" />
    </ScrollAreaPrimitive.Root>
  )
}

export default HorizontalScroll
