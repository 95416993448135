import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  cn,
  Loader,
} from '@opoint/infomedia-storybook'
import { T } from '@transifex/react'
import { useAppSelector } from '../hooks/useAppSelector'
import { getDeleteAlertInProgress } from '../../selectors/alertsSelectors'
import { useAppDispatch } from '../hooks/useAppDispatch'

type AlertDeleteConfirmationDialogProps = {
  show: boolean
  onClose: () => void
  alertId: number
}

const AlertDeleteConfirmationDialog = ({ alertId, show, onClose }: AlertDeleteConfirmationDialogProps) => {
  const isDeleteInProgress = useAppSelector(getDeleteAlertInProgress)
  const dispatch = useAppDispatch()

  return (
    <AlertDialog open={show}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            <T _str="Are you sure you want to delete this alert?" />
          </AlertDialogTitle>
          <AlertDialogDescription>
            <T _str="No alerts will be sent to recipients after deletion. This action is irreversible." />
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel disabled={isDeleteInProgress} onClick={onClose}>
            <T _str="Cancel" />
          </AlertDialogCancel>
          <AlertDialogAction
            className={cn(isDeleteInProgress && 'text-transparent')}
            data-cy="delete"
            onClick={() => dispatch({ type: 'DELETE_ALERT_CONFIRM', payload: { alertId } })}
          >
            <T _str="Delete" />
            {isDeleteInProgress && <Loader className="absolute border-sky.cloudy/50 border-r-white" size="small" />}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default AlertDeleteConfirmationDialog
