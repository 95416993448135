import { t } from '@transifex/native'
import { switchMap } from 'rxjs/operators'

import { ActionsObservable } from 'redux-observable'
import {
  EntityRepositoryActions,
  EntityRepositoryFetchFilterDetailFailureAction,
} from '../../../actions/entityRepository'
import { SearchActions, SearchChangeDateRangeFailureAction } from '../../../actions/search'
import { ErrorEpic, handlerError } from '.'

const searchErrorTypes: ErrorEpic<
  SearchChangeDateRangeFailureAction['type'] | EntityRepositoryFetchFilterDetailFailureAction['type']
> = {
  ['SEARCH_CHANGE_DATERANGE_FAILURE']: {
    isSuccess: false,
    message: t('We were not able to change date range'),
    toastId: 'SEARCH_CHANGE_DATERANGE_FAILURE',
  },
  ['FETCH_FILTER_DETAIL_FAILURE']: {
    isSuccess: false,
    message: t('We were not able to fetch filter details'),
    toastId: 'FETCH_FILTER_DETAIL_FAILURE',
  },
}

const searchErrorEpic: (action: ActionsObservable<SearchActions | EntityRepositoryActions>) => void = (action$) =>
  action$.pipe(switchMap(({ type }) => handlerError(searchErrorTypes, type)))

export default searchErrorEpic
