import { DataTrending24Regular } from '@fluentui/react-icons'
import { ElementRef, forwardRef } from 'react'
import { NavLink } from 'react-router-dom'
import { t } from '@transifex/native'

import { NavigationComponentProps, NavigationSectionKey } from '../../../../../helpers/navigation'
import { getFirstStatistics } from '../../../../../selectors/statisticsSelectors'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { useDefaultHomeUrl } from '../../../../hooks/useDefaultHomeUrl'
import { NavigationSection, NavigationSectionHeaderLink } from '../../primitives/NavigationSection'
import { SavedStatisticsNavigationList } from './SavedStatisticsNavigationList'

export const SavedStatisticsNavigationSection = forwardRef<ElementRef<typeof NavLink>, NavigationComponentProps>(
  ({ isExpanded, ...props }, ref) => {
    const firstStatistics = useAppSelector(getFirstStatistics)
    const defaultHomeUrl = useDefaultHomeUrl()

    return (
      <NavigationSection>
        <NavigationSectionHeaderLink
          label={t('Saved statistics')}
          isExpanded={Boolean(isExpanded)}
          ref={ref}
          to={
            firstStatistics?.id
              ? {
                  pathname: `/statistics/${firstStatistics.id}`,
                }
              : defaultHomeUrl
          }
          section={NavigationSectionKey.SAVED_STATISTICS}
          {...props}
        >
          <DataTrending24Regular className="w-7 shrink-0 text-sky.4" />
        </NavigationSectionHeaderLink>

        <SavedStatisticsNavigationList />
      </NavigationSection>
    )
  },
)
SavedStatisticsNavigationSection.displayName = 'SavedStatisticsNavigationSection'
