import { Eye24Regular } from '@fluentui/react-icons'
import { t } from '@transifex/native'
import { ElementRef, forwardRef } from 'react'
import { NavLink } from 'react-router-dom'

import { NavigationComponentProps, NavigationSectionKey } from '../../../../helpers/navigation'
import { NavigationSection, NavigationSectionHeaderLink } from '../primitives/NavigationSection'

export const CustomerViewNavigationSection = forwardRef<ElementRef<typeof NavLink>, NavigationComponentProps>(
  ({ isExpanded, ...props }, ref) => (
    <NavigationSection>
      <NavigationSectionHeaderLink
        label={t('Customer view')}
        isExpanded={Boolean(isExpanded)}
        ref={ref}
        to="/customer_view"
        section={NavigationSectionKey.CUSTOMER_VIEW}
        {...props}
      >
        <Eye24Regular className="text-sea. w-7 shrink-0" />
      </NavigationSectionHeaderLink>
    </NavigationSection>
  ),
)
CustomerViewNavigationSection.displayName = 'CustomerViewNavigationSection'
