import { T } from '@transifex/react'

import { Button } from '@opoint/infomedia-storybook'
import { Dismiss24Regular } from '@fluentui/react-icons'

type Props = {
  onAction: () => void
  onClose: () => void
}

const ChangeLogPopover = ({ onAction, onClose }: Props) => {
  return (
    <div className="absolute bottom-6 right-6 z-50 flex rounded bg-sky.1 px-4 py-3 text-white shadow">
      <div className="max-w-80">
        <h3 className="px-0 py-3 font-bold">
          <T _str="Platform Announcement, Redesign" /> 🎉
        </h3>
        <p>
          <T _str="We have released a new design for the Infomedia platform to enhance your user experience while retaining all the functionalities you know. " />
        </p>
        <Button
          name="link"
          variant="text"
          className="px-0 text-white no-underline hover:text-sand.3"
          onClick={onAction}
        >
          <T _str="Read more" />
        </Button>
      </div>
      <Button name="link" size="icon" className="hover:text-sand.3" onClick={onClose}>
        <Dismiss24Regular />
      </Button>
    </div>
  )
}

export default ChangeLogPopover
