import React from 'react'
import { ErrorCircle16Regular } from '@fluentui/react-icons'
import { T } from '@transifex/react'

export const ErrorBreadcrumb = (): React.JSX.Element => (
  <span className="flex items-center font-normal text-berry.2">
    <ErrorCircle16Regular className="mr-1 shrink-0" />
    <T _str="Unknown" />
  </span>
)
