import { Delete24Regular } from '@fluentui/react-icons'
import { Tooltip, TooltipTrigger, Button, TooltipContent, TooltipPortal } from '@opoint/infomedia-storybook'
import { T } from '@transifex/react'
import { useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { getAlertsRecipientsListQueryKey, useAlertsRecipientsDestroy } from '../../../api/alerts/alerts'
import { Recipient } from '../../../api/opoint.schemas'
import { useAppSelector } from '../../hooks/useAppSelector'
import { getAlertRecipientsListOpen } from '../../../selectors/uiSelectors'

type RecipientDeleteTooltipProps = {
  id_recipient: number
}

const RecipientRemoveButton = ({ id_recipient }: RecipientDeleteTooltipProps) => {
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()

  const { alertId, mailLogId } = useParams()
  const { alertId: id } = useAppSelector(getAlertRecipientsListOpen)
  const activeAlertId = alertId ? parseInt(alertId, 10) : id
  const recipientDelete = useAlertsRecipientsDestroy()

  if (!activeAlertId) {
    return null
  }

  const onRecipientDeleteSuccess = () => {
    queryClient.setQueryData<Recipient[]>(
      getAlertsRecipientsListQueryKey(activeAlertId, {
        id_maillog: mailLogId !== 'next' ? mailLogId : undefined,
      }),
      (data) => data?.filter((data) => data.id_recipient !== id_recipient),
    )
    dispatch({ type: 'RECIPIENT_DELETE_CONFIRMATION_CLOSE' })
  }

  const handleDelete = () => {
    dispatch({
      type: 'RECIPIENT_DELETE_CONFIRMATION_OPEN',
      payload: {
        onAccept: () =>
          recipientDelete.mutate(
            { id: activeAlertId, idRecipient: id_recipient.toString() },
            {
              onSuccess: onRecipientDeleteSuccess,
            },
          ),
        onDismiss: () => dispatch({ type: 'RECIPIENT_DELETE_CONFIRMATION_CLOSE' }),
      },
    })
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          variant="text"
          size="icon"
          onClick={handleDelete}
          loading={recipientDelete.isLoading}
          className="text-sky.1"
        >
          <Delete24Regular />
        </Button>
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent>
          <T _str="Remove recipient" />
        </TooltipContent>
      </TooltipPortal>
    </Tooltip>
  )
}

export default RecipientRemoveButton
