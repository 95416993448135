import { useT } from '@transifex/react'

import { ActionBarRightItem } from '../types'
import { isEditAlertFormDirty } from '../../../../selectors/alertsSelectors'
import { useAppSelector } from '../../../hooks/useAppSelector'

import useIsUserPermitted from '../../../hooks/useIsUserPermitted'
import { MODULES } from '../../../constants/permissions'
import AlertCancelAction from './actions/AlertCancelAction'
import AlertDeleteAction from './actions/AlertDeleteAction'
import AlertSaveAction from './actions/AlertSaveAction'

export const useEditAlertActions = (): ActionBarRightItem[] => {
  const t = useT()
  const isFormDirty = useAppSelector(isEditAlertFormDirty)

  const hasAlertPermission = useIsUserPermitted({
    module: 'ALERT_MODULE',
    permissions: [MODULES.ALERT_MODULE.ON],
  })

  if (!hasAlertPermission) {
    return []
  }

  return [
    {
      name: t('Delete alert'),
      Action: AlertDeleteAction,
    },
    {
      name: isFormDirty ? t('Cancel changes') : t('Cancel'),
      Action: AlertCancelAction,
    },
    {
      name: t('Save'),
      Action: AlertSaveAction,
    },
  ]
}
