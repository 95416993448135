import { Loader } from '@opoint/infomedia-storybook'
import { T, useT } from '@transifex/react'
import { useEffect, useMemo, useRef } from 'react'
import { parseISO } from 'date-fns'
import { useSearchParams } from 'react-router-dom'

import { Search48Regular } from '@fluentui/react-icons'
import { useInviteDirectList } from '../../../../../api/invite/invite'
import { useAuthInviteSearchRetrieveInfinite } from '../../../../../api/auth/auth'
import { extractPageNumber } from '../../../../../helpers/pagination'
import UserInvitationsTable from '../UserInvitationsTable'
import { InvitationSentStatusMapping, InvitationsTableItem } from '../../../../types/userManagement'
import ErrorWithRetry from '../../../../common/ErrorWithRetry'
import EmptyData from '../../../../../pages/empty/EmptyData'

const AllList = () => {
  const loadingElement = useRef<HTMLDivElement>(null)
  const [searchParams] = useSearchParams()
  const searchTerm = searchParams.get('search') ?? ''
  const t = useT()

  const {
    data: authInvitesData,
    error: allError,
    fetchNextPage,
    isLoading: isAllLoading,
    isFetching: isAllFetching,
    hasNextPage,
    refetch,
  } = useAuthInviteSearchRetrieveInfinite(
    { page_size: 15, q: searchTerm },
    {
      query: {
        keepPreviousData: true,
        getNextPageParam: (lastPage) => extractPageNumber(lastPage?.next),
        staleTime: 5000,
      },
    },
  )

  const pages = useMemo(() => authInvitesData?.pages.filter(Boolean) || [], [authInvitesData?.pages])
  const allData = useMemo(() => pages.flatMap((page) => page.results) || [], [pages])

  const { data: invitations, error: invitationsError } = useInviteDirectList(
    { q: searchTerm },
    {
      query: { keepPreviousData: true },
    },
  )

  useEffect(() => {
    if (!allData.length || !hasNextPage) {
      return
    }

    const observer = new IntersectionObserver((entries) => {
      if (entries[0]?.isIntersecting) {
        void fetchNextPage()
      }
    })

    if (loadingElement.current) {
      observer.observe(loadingElement.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [allData, fetchNextPage, hasNextPage])

  const showLoader = isAllLoading && pages.length === 0

  if (!!allError || !!invitationsError) {
    return (
      <div className="p-6">
        <ErrorWithRetry
          primaryText={t("Can't load the content right now.")}
          secondaryText={t('Please try again later.')}
          retry={refetch}
        />
      </div>
    )
  }

  const preparedData: InvitationsTableItem[] = allData.map((item) => {
    const invitation = invitations?.results.find((invitation) => invitation.token === item.id)
    const status = InvitationSentStatusMapping[Number(invitation?.status)] || (item.is_active ? 'Active' : 'Inactive')

    return {
      email: item?.email || invitation?.email || '',
      id: Number(item.id),
      invitation,
      isUser: item.is_user,
      ...(item.last_login && { lastLogin: parseISO(item.last_login).getTime() }),
      name: item.name,
      role: item.role,
      status,
    }
  })

  if (showLoader) {
    return (
      <div className="flex size-full items-center justify-center">
        <Loader />
      </div>
    )
  }

  if (preparedData.length === 0) {
    return (
      <div className="flex size-full items-center justify-center">
        <EmptyData
          text={t('No results found. Try adjusting your search to find what you’re looking for.')}
          icon={Search48Regular}
        />
      </div>
    )
  }

  return (
    <div>
      <UserInvitationsTable invitationItems={preparedData} allList />

      {hasNextPage ? (
        <div ref={loadingElement} className="flex h-16 w-full items-center justify-center p-s">
          {isAllFetching && <Loader size="small" />}
        </div>
      ) : (
        <p className="mx-auto my-11 w-full text-center text-sm text-sky.cloudy">
          <T _str="You have reached the end. Nothing else to see here." />
        </p>
      )}
    </div>
  )
}

export default AllList
