import { t } from '@transifex/native'
import copy from 'copy-to-clipboard'
import * as R from 'ramda'
import Rx from 'rx-dom'
import { fromUnixTime, isAfter } from 'date-fns'

import { ContactTypes } from '../../components/constants/suggestions'
import { ContactFormValues } from '../../components/contacts/ContactsForm/ContactEditor/types'
import { GroupFormValues } from '../../components/contacts/ContactsForm/GroupEditor/types'
import { CommonRecipientFilter, ContactDetail, ContactFilter } from '../../components/types/contact'
import { isGroup } from '../../guards/isContact'
import config from '../common/config'
import type { Contact, Group, Recipient } from '../flow'

// @sig Contact -> Contact -> boolean
// @ts-expect-error: Muted so we could enable TS strict mode
export const eqContacts = R.eqBy(R.prop('id'))

// @sig ContactFilter -> ContactFilter -> boolean
// @ts-expect-error: Muted so we could enable TS strict mode
export const eqContactFilters = R.allPass([R.eqBy(R.prop('type')), R.eqBy(R.path(['entity', 'id']))])

// @sig ContactFilter -> string
export const getContactFilterName = R.cond([
  // @ts-expect-error: Muted so we could enable TS strict mode
  [R.propEq('type', 'person'), ({ entity: contact }) => getContactFullName(contact)],
  // @ts-expect-error: Muted so we could enable TS strict mode
  [R.propEq('type', 'user'), ({ entity: contact }) => getContactFullName(contact)],
  [R.propEq('type', 'group'), R.path(['entity', 'name'])],
  [R.propEq('type', 'email'), R.path(['entity', 'value'])],
  [R.propEq('type', 'phoneNumber'), R.path(['entity', 'value'])],
  [R.T, R.always('Unknown type')],
])

export const getContactTypeAndName = (suggestion: ContactFilter | CommonRecipientFilter) => {
  const { type } = suggestion

  switch (type) {
    case ContactTypes.Person:
      return { name: getContactFullName(suggestion.entity as Contact), type: t('Contact') }

    case ContactTypes.User:
      return { name: getContactFullName(suggestion.entity as Contact), type: t('User') }

    case ContactTypes.Group:
      return { name: isGroup(suggestion.entity) && suggestion.entity.name, type: t('Group') }

    case ContactTypes.Email:
      return { name: suggestion.entity.value, type: t('Email') }

    case ContactTypes.PhoneNumber:
      return { name: suggestion.entity.value, type: t('Phone number') }

    default:
      return { name: t('Unknown type'), type: t('Unknown type') }
  }
}

export const contactRecipientDataTransformToFilter = (
  recipients: Recipient,
  listByType: { person: Array<ContactDetail>; group: Array<Group> },
) =>
  // @ts-expect-error: Muted so we could enable TS strict mode
  R.map(
    (recipient) => ({
      // @ts-expect-error: Muted so we could enable TS strict mode
      type: recipient.type,
      // @ts-expect-error: Muted so we could enable TS strict mode
      entity: listByType[recipient.type]
        ? // @ts-expect-error: Muted so we could enable TS strict mode
          R.find(R.propEq('id', recipient.id))(listByType[recipient.type])
        : {
            // @ts-expect-error: Muted so we could enable TS strict mode
            ...recipient,
            // @ts-expect-error: Muted so we could enable TS strict mode
            id: recipient.value ?? recipient.id, // Fallback to when type === user
          },
    }),
    recipients,
  )

/**
 * Retrieves contacts from API and transforms them into a Promise.
 * @returns {*}
 */
export async function getContacts(): Promise<ContactDetail[]> {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api('/contacts/'),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export function getContactFullName(contact: Contact) {
  // @ts-expect-error: Muted so we could enable TS strict mode
  const firstName = contact.firstName ?? contact.name
  const lastName = contact.lastName ?? ''

  return `${firstName} ${lastName}`
}

export async function getContactDetail(contactId: number): Promise<ContactDetail> {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/contacts/${contactId}/`),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}
export async function saveContact(contactId: number | string, updatedData: ContactFormValues): Promise<ContactDetail> {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: contactId ? config.url.api(`/contacts/${contactId}/`) : config.url.api('/contacts/'),
    method: contactId ? 'PUT' : 'POST',
    body: JSON.stringify(updatedData),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export async function deleteContact(contactId: number | string, locale = 'en-GB', forceFlag = false) {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/contacts/${contactId}${forceFlag ? '/?force=true' : ''}/`),
    method: 'DELETE',
    responseType: 'text',
  })
  const requestHeadersWithLanguage = R.assocPath(['headers', 'accept-language'], locale, requestHeaders)

  return Rx.DOM.ajax(requestHeadersWithLanguage)
    .toPromise()
    .then(({ response }) => response)
}

export async function getGroups(): Promise<Group[]> {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api('/contact-groups/'),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export async function getGroupDetail(groupId: number): Promise<Group> {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/contact-groups/${groupId}/`),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export async function saveGroup(data: GroupFormValues): Promise<Group> {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: data.id ? config.url.api(`/contact-groups/${data.id}/`) : config.url.api('/contact-groups/'),
    method: data.id ? 'PUT' : 'POST',
    body: JSON.stringify(data),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export async function deleteGroup(groupId: number, locale = 'en-GB') {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/contact-groups/${groupId}/`),
    method: 'DELETE',
    responseType: 'text',
  })
  const requestHeadersWithLanguage = R.assocPath(['headers', 'accept-language'], locale, requestHeaders)

  return Rx.DOM.ajax(requestHeadersWithLanguage)
    .toPromise()
    .then(({ response }) => response)
}

export const checkedGroupProp = 'checked' // name of group prop for redux form checkbox(True/False)

export function checkGroup(group: Group) {
  return R.assoc(checkedGroupProp, true, group)
}

export const sortByProp: (propToBeSortBy: string, groupList: Array<Group>) => Array<Group> = R.curry((prop, list) =>
  R.sortBy(
    R.compose(
      R.when(
        R.is(String),
        R.toLower, // upper case props won't be before lowercase props
      ),
      // @ts-expect-error: Muted so we could enable TS strict mode
      R.prop(prop),
    ),
    list,
  ),
)

/*
  Add all existing groups into empty contact
*/
export function createEmptyContactWithGroups(groups: Array<Group>) {
  return {
    firstName: null,
    lastName: null,
    email: null,
    mobile: null,
    email_forwards: false,
    pause: null,
    groups,
  }
}

/*
  Concat all existing groups with groups that belogs to specific contact, without duplicates
*/
export function joinCheckGroupsWithRest(groupList: Array<Group>, contactGroups: Array<Group> = []) {
  const checkedGroups = R.map(checkGroup, contactGroups) // set check for contact specific groups
  const uncheckedGroups = R.differenceWith((x, y) => R.eqProps('id', x, y), groupList, checkedGroups)

  return R.concat(checkedGroups, uncheckedGroups)
}

/*
  Check if timestamp value is validly defined and in the future, because if
  pause timestamp is in the past it's not active
*/
export function isAlertPauseTimeStampValid(timestamp: number): boolean {
  if (timestamp) {
    return isAfter(fromUnixTime(timestamp), new Date())
  }

  return false
}

// Copies recipients info to clipboard
// infoType parameter determines which info to copy (email or mobile)
export const copyRecipientInfo = async (
  recipients: Array<ContactFilter | CommonRecipientFilter>,
  infoType: 'email' | 'mobile',
  // @ts-expect-error: Muted so we could enable TS strict mode
): boolean => {
  // Group detail fetching promises, resolved after reducing other recipient types
  const groupPromises: Promise<Group>[] = []

  // @ts-expect-error: Muted so we could enable TS strict mode
  const infoToCopy = recipients.reduce((acc, { type, entity }) => {
    if (type === 'person') {
      return [...acc, entity[infoType]]
    }

    if (type === 'email' || type === 'phoneNumber') {
      return [...acc, entity.value]
    }

    if (type === 'user') {
      return [...acc, entity.email]
    }

    if (type === 'group') {
      groupPromises.push(getGroupDetail(entity.id))
    }

    return acc
  }, [])

  Promise.all(groupPromises).then((res) => {
    res.forEach((group) => {
      // @ts-expect-error: Muted so we could enable TS strict mode
      group.contacts?.forEach((contact) => infoToCopy.push(contact[infoType]))
    })

    // @ts-expect-error: Muted so we could enable TS strict mode
    return infoToCopy.length > 0 && copy(R.uniq(infoToCopy).join(', '))
  })
}
