import { t } from '@transifex/native'

import configureEpics from './epics/configureEpics'
import errorEpics from './epics/web/errorEpics'
import navigationEpics from './epics/web/navigationEpics'
import uiEpics from './epics/web/uiEpics'

const webEpics = [...errorEpics, ...navigationEpics, ...uiEpics]

export const rootEpic = configureEpics(
  {
    // ... platform deps in a future can go here
    translate: t,
  },
  webEpics,
)

// This is a performance optimization, when we nagigate from one search
// to another, we clear article listing so that articles are not re-rendered
// before route change
export const routeChangedMiddleware = () => (next) => (action) => {
  if (action._isScalar) {
    action = action.value
    // eslint-disable-next-line no-console
    console.warn('-> action was scalar')
  }

  if (action.type === 'ROUTER_LOCATION_CHANGE') {
    window.Intercom?.('update')
  }

  return next(action)
}
