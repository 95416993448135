import { Send24Regular } from '@fluentui/react-icons'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
  useToast,
} from '@opoint/infomedia-storybook'
import { useLocation } from 'react-router'

import { T, useT } from '@transifex/react'
import { useAlertsHistoryResendCreate } from '../../../../../api/alerts/alerts'
import { extractArticlesFromAlert, getIsSendDisabled } from '../../../../../helpers/alerts'
import { recipientsEntitiesApiNormalize } from '../../../../../opoint/alerts'
import {
  getAlertHistoryById,
  getCurrentAlertHistoryItem,
  getCurrentAlertMail,
} from '../../../../../selectors/alertsSelectors'
import { getAlertEmail } from '../../../../../selectors/contactSelectors'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { ActionBarDropdownMenuItem } from '../../ActionBarDropdownMenuItem'
import { ActionBarActionsProps, ActionVariant } from '../../types'
import { useRequiredParams } from '../../../../hooks/useRequiredParams'

const AlertSendAction = ({ variant = ActionVariant.LONG, name }: ActionBarActionsProps) => {
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const params = useRequiredParams(['alertId'])
  const { toast } = useToast()

  const currentAlertMail = useAppSelector(getCurrentAlertMail)
  const alertHistory = useAppSelector(getAlertHistoryById(parseInt(params?.alertId, 10)))
  const { alertId, historyId, historyTimestamp } = useAppSelector(getCurrentAlertHistoryItem) || {}
  const recipientsEmails = useAppSelector(getAlertEmail)
  const t = useT()

  const isNextAlert = pathname.includes('next')

  const articles = extractArticlesFromAlert(currentAlertMail)
  const isSendDisabled = getIsSendDisabled(currentAlertMail, articles, isNextAlert, alertHistory)

  const { mutate: resendAlertHistoryItem, isLoading } = useAlertsHistoryResendCreate({
    mutation: {
      onSuccess: () => {
        toast({
          title: t('Alert was sent'),
          variant: 'success',
        })
      },
      onError: () => {
        toast({
          title: t('Alert failed to send'),
          variant: 'destructive',
        })
      },
    },
  })

  const onClickAction = () => {
    if (isNextAlert) {
      dispatch({ type: 'SEND_ALERT_NOW' })
    } else {
      resendAlertHistoryItem({
        id: alertId!,
        idItem: historyId!.toString(),
        stimestamp: historyTimestamp!.toString(),
        data: { recipients: recipientsEntitiesApiNormalize(recipientsEmails) },
      })
    }
  }

  const trigger = () => {
    if (variant === ActionVariant.MENU) {
      return (
        <ActionBarDropdownMenuItem
          onSelect={(e) => e.preventDefault()}
          disabled={isSendDisabled}
          data-cy="alertSendBtn"
        >
          <Send24Regular />
          {name}
        </ActionBarDropdownMenuItem>
      )
    }

    if (variant === ActionVariant.LONG) {
      return (
        <Button variant="outline" disabled={isSendDisabled} data-cy="alertSendBtn" loading={isLoading}>
          <Send24Regular />
          {name}
        </Button>
      )
    }

    return (
      <TooltipTrigger asChild>
        <Button variant="outline" size="icon" disabled={isSendDisabled} data-cy="alertSendBtn" loading={isLoading}>
          <Send24Regular />
          <span className="sr-only">{name}</span>
        </Button>
      </TooltipTrigger>
    )
  }

  return (
    <AlertDialog>
      <Tooltip>
        <AlertDialogTrigger asChild>{trigger()}</AlertDialogTrigger>
        <TooltipPortal>
          <TooltipContent>{name}</TooltipContent>
        </TooltipPortal>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              {isNextAlert ? (
                <T _str="Are you sure you want to send the alert now?" />
              ) : (
                <T _str="Are you sure you want to send the alert again?" />
              )}
            </AlertDialogTitle>
          </AlertDialogHeader>
          <AlertDialogDescription>
            <T _str="The alert will be delivered to all currently subscribed recipients." />
          </AlertDialogDescription>
          <AlertDialogFooter>
            <AlertDialogCancel data-cy="sendAgainAlertCancelBtn">
              <T _str="Cancel" />
            </AlertDialogCancel>
            <AlertDialogAction onClick={onClickAction} data-cy="sendAgainAlertBtn">
              {isNextAlert ? <T _str="Send now" /> : <T _str="Send again" />}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </Tooltip>
    </AlertDialog>
  )
}

export default AlertSendAction
