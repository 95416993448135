import { t } from '@transifex/native'
import { switchMap } from 'rxjs/operators'

import { ActionsObservable } from 'redux-observable'

import { AuthActions, ServerNotRespondingAction, TokenExpiredAction } from '../../../actions/auth'
import { ErrorEpic, handlerError } from '.'

const portalErrorTypes: ErrorEpic<TokenExpiredAction['type'] | ServerNotRespondingAction['type']> = {
  ['TOKEN_EXPIRED']: {
    isSuccess: false,
    message: t('Authentication token expired, please log in again.'),
    toastId: 'TOKEN_EXPIRED',
  },
  ['SERVER_NOT_RESPONDING']: {
    isSuccess: false,
    message: t('We are sorry, server is not responding, please, try again later.'),
    toastId: 'SERVER_NOT_RESPONDING',
  },
}

const portalErrorEpic: (action: ActionsObservable<AuthActions>) => void = (action$) =>
  action$.pipe(switchMap(({ type }) => handlerError(portalErrorTypes, type)))

export default portalErrorEpic
