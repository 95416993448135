import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  useToast,
} from '@opoint/infomedia-storybook'
import { useQueryClient } from '@tanstack/react-query'
import { T, useT } from '@transifex/react'

import { getNotificationsListQueryKey, useNotificationsDestroy } from '../../../api/notifications/notifications'
import { Notification } from '../../../api/opoint.schemas'

type Props = {
  notification: Notification
  successAction: () => void
}

const NotificationDeleteConfirm = ({ notification, successAction }: Props) => {
  const { mutate: handleDeleteNotification } = useNotificationsDestroy()
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const t = useT()

  const handleDelete = () => {
    handleDeleteNotification(
      { id: notification.id },
      {
        onSuccess: () => {
          void queryClient.invalidateQueries({
            queryKey: getNotificationsListQueryKey(),
          })

          successAction()
        },
        onError: () => {
          toast({
            title: t('We are unable to delete this notification'),
            variant: 'destructive',
          })
        },
      },
    )
  }

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button>
          <T _str="Delete notification" />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            <T _str="Do you really want to delete this notification?" />
          </AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>
            <T _str="Cancel" />
          </AlertDialogCancel>
          <AlertDialogAction onClick={handleDelete}>
            <T _str="Confirm" />
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default NotificationDeleteConfirm
