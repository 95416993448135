import { useT } from '@transifex/react'
import { useLocation, useParams } from 'react-router-dom'
import useIsUserAdmin from '../../../hooks/useIsUserAdmin'

import { ActionBarRightItem } from '../types'
import { getRecipientCount } from '../../../alerts/RecipientsListModal/helpers'
import { useAlertsRecipientsList } from '../../../../api/alerts/alerts'
import useIsUserPermitted from '../../../hooks/useIsUserPermitted'
import { MODULES } from '../../../constants/permissions'
import AlertCreateNewAction from './actions/AlertCreateNewAction'
import AlertEditAction from './actions/AlertEditAction'
import AlertForwardAction from './actions/AlertForwardAction'
import AlertRemoveArticlesAction from './actions/AlertRemoveArticlesAction'
import AlertSeeRecipientsAction from './actions/AlertSeeRecipients'
import AlertSendAction from './actions/AlertSendAction'
import AlertViewInBrowser from './actions/AlertViewInBrowser'
import AlertViewInBrowserEnd from './actions/AlertViewInBrowserEnd'

export const useDefaultAlertsActions = (): ActionBarRightItem[] => {
  const t = useT()
  const { pathname } = useLocation()
  const isSuperAdmin = useIsUserAdmin()

  const hasAlertPermission = useIsUserPermitted({
    module: 'ALERT_MODULE',
    permissions: [MODULES.ALERT_MODULE.ON],
  })

  const isNextAlert = pathname.includes('next')
  const isViewingAlertInBrowser = pathname.includes('view')

  const { alertId, mailLogId } = useParams<{ alertId: string; mailLogId: string }>()
  const { data, isSuccess } = useAlertsRecipientsList(
    parseInt(alertId as string, 10),
    {
      ...(mailLogId !== 'next' ? { id_maillog: mailLogId } : {}),
    },
    { query: { enabled: !!mailLogId } },
  )

  const showRecipientCount = isSuccess
  const recipientCount = getRecipientCount(data ?? [])

  if (!hasAlertPermission) {
    return []
  }

  return isViewingAlertInBrowser
    ? [{ name: t('Back to alert'), Action: AlertViewInBrowserEnd }]
    : [
        ...(!alertId
          ? [
              {
                name: t('Create new alert'),
                Action: AlertCreateNewAction,
              },
            ]
          : []),

        ...(alertId
          ? [
              {
                name: t('Edit alert'),
                Action: AlertEditAction,
              },
              {
                name: isNextAlert ? t('Send now') : t('Send again'),
                Action: AlertSendAction,
              },
              ...(!isNextAlert
                ? [
                    {
                      name: t('Forward'),
                      Action: AlertForwardAction,
                    },
                  ]
                : []),
              {
                name: t(`See recipients`) + (showRecipientCount ? ` (${recipientCount})` : ''),
                Action: AlertSeeRecipientsAction,
              },
              ...(isSuperAdmin && !isNextAlert ? [{ name: t('View in browser'), Action: AlertViewInBrowser }] : []),
              ...(isNextAlert
                ? [
                    {
                      name: t('Remove articles'),
                      Action: AlertRemoveArticlesAction,
                    },
                  ]
                : []),
            ]
          : []),
      ]
}
