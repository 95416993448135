import { TagColor } from '@opoint/infomedia-storybook'
import { t } from '@transifex/native'

import { AlertTag, ChildAlertTag, GeneralTag, Tag, TagVisibilityType } from '../components/types/tag'
import { Trash } from '../components/types/trash'
import { TAG_TYPES } from '../opoint/tags'

export const isDefaultMobileTag = (tag: Tag | AlertTag | undefined) => tag?.name === t('Saved')

export const mapColorToStyle = (color: TagColor) => {
  switch (color) {
    case TagColor.PINK:
      return 'bg-berry.4'
    case TagColor.RED:
      return 'bg-berry.2'
    case TagColor.TURQUOISE:
      return 'bg-sea.4'
    case TagColor.DARKBLUE:
      return 'bg-sea.1'
    case TagColor.LIGHTGREEN:
      return 'bg-forest.5'
    case TagColor.GREEN:
      return 'bg-forest.3'
    case TagColor.YELLOW:
      return 'bg-sand.4'
    case TagColor.ORANGE:
      return 'bg-sand.3'
    case TagColor.BLUE:
      return 'bg-sky.4'
    default:
      return ''
  }
}

// Tag currently has whole TAG_TYPES as possible type, however this is the case only because correctly handling the type would break typescript on many places
// It should be safe to assume Tag has onnly Keyword (1) and Mentomenter (2) as possible types
export const isTag = (tag: GeneralTag): tag is Tag => {
  return tag.type === TAG_TYPES.KEYWORD || tag.type === TAG_TYPES.MENTOMETER
}

export const isAlertTag = (tag: GeneralTag): tag is AlertTag => {
  return tag.type === TAG_TYPES.ALERT && 'children' in tag
}

// TODO: Consider using separate type for the ChildAlertTag
export const isChildAlertTag = (tag: GeneralTag): tag is ChildAlertTag => {
  return tag.type === TAG_TYPES.ALERT && !('children' in tag)
}

export const isTrashTag = (tag: GeneralTag): tag is Trash => {
  return tag.type === TAG_TYPES.GLOBAL_TRASH || tag.type === TAG_TYPES.PROFILE_TRASH
}

export const isTagSelected = (tag: GeneralTag, articleTags) => {
  if (isAlertTag(tag)) {
    return !!tag.children.find((innerTag) => articleTags?.[innerTag.id])
  }

  return !!articleTags?.[tag.id]
}

export const shouldShowTag = (tag: GeneralTag) => {
  return (
    (isTag(tag) && tag.category === 0 && tag.visibility === TagVisibilityType.Always) ||
    tag.type === TAG_TYPES.PROFILE_TRASH ||
    tag.type === TAG_TYPES.ALERT ||
    tag.type === TAG_TYPES.GLOBAL_TRASH
  )
}
