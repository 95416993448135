import { useRef, useEffect } from 'react'
import { t } from '@transifex/native'

import { LOCALES } from '../constants/common'
import { IMAGE_URL_BASE } from '../constants/settings'
import { KeyValue } from '../types/settings'

export const executeIfFunction: (fn) => void = (fn) => {
  typeof fn === 'function' && fn()
}

// Transforms string of format 'key=val,key2=val2,...' to array of objects [{key: 'key', value: 'val'},{key: 'key2', value: 'val2'},...]
export const keyValueParse = (str?: string, sep = ','): KeyValue[] => {
  if (!str) {
    return []
  }

  return str.split(sep)?.map((item) => {
    const [key, value] = item.split('=')

    return {
      key,
      value,
    }
  })
}

// Transforms array of objects [{key: 'key', value: 'val'},{key: 'key2', value: 'val2'},...] to string of format 'key=val,key2=val2,...'
export const keyValueSerialize = (obj?: KeyValue[], sep = ','): string => {
  if (!Array.isArray(obj) || obj.length === 0) {
    return ''
  }

  return obj?.map(({ key, value }) => `${key}=${value}`).join(sep)
}

export const changeImageUrlBase = (str: string): string => {
  return str.replace(IMAGE_URL_BASE.old, IMAGE_URL_BASE.new)
}

// Custom hook for keeping previous props/state. Useful for comparing states in useEffect hook.
export const usePrevious = <T>(value: T): T => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })

  return ref.current as T
}

export const swapSignInString = (str: string): string => str.replace(/^(\+|-)/, (sign) => (sign === '-' ? '+' : '-'))

// Check whether a number or string is numeric
export const isNumber: (n: number | string) => boolean = (n: number | string) => {
  return !isNaN(Number(n))
}

// Abbreviate a number to millions (M) or thousands (K)
export const abbreviateNumber = (n: number) => {
  const abbreviate = (cap: number, letter: string) => Math.round((n / cap) * 10) / 10 + letter

  return n >= 1000000 ? abbreviate(1000000, 'M') : n >= 1000 ? abbreviate(1000, 'K') : n
}

export const alterSoMeSummary: (summary, type, retweeted_user?) => string = (summary, type, retweeted_user?) => {
  const lastIndex = summary?.lastIndexOf(' ') ?? -1

  switch (true) {
    case type === 'link':
      return summary.substring(0, lastIndex)

    case type === 'retweet':
      let newSummary = summary
      const RTIncluded = summary?.includes('RT')

      const removedRT = summary.split(':')
      removedRT.shift() // Removing first element by using shift

      if (RTIncluded) {
        newSummary = removedRT.join(' ')
      }

      return t('Retweeted @{retweeted_user}: {newSummary}', { retweeted_user, newSummary })

    case type === 'reply':
      return t('Replying to {summary}', { summary })

    default:
      return summary
  }
}

export const MIN_CUSTOM_FOLDER_ID = 1000000000

export const getMasterDomainBasedOnLocale = (locale: string) => {
  switch (locale) {
    case LOCALES.DK:
      return 'dk'
    case LOCALES.SE:
      return 'se'
    case LOCALES.NO_NN:
    case LOCALES.NO_NB:
      return 'no'
    case LOCALES.FI:
      return 'fi'
    default:
      return 'org'
  }
}
