import { FluentIcon } from '@fluentui/react-icons'
import { cn } from '@opoint/infomedia-storybook'
import { PropsWithChildren } from 'react'

type EmptyDataProps = PropsWithChildren<{
  icon: FluentIcon
  text: string
  className?: string
}>

const EmptyData = ({ icon: PropsIcon, text, children, className }: EmptyDataProps) => (
  <div className={cn('flex h-full w-full flex-col items-center justify-center', className)}>
    <div className="flex max-w-[30rem] flex-col items-center justify-center gap-6 text-center">
      {PropsIcon && (
        <div className="flex h-24 w-24 items-center justify-center rounded-full bg-grey.8">
          <PropsIcon className="text-sky.cloudy" />
        </div>
      )}
      <p className="text-xl text-sky.1">{text}</p>
      {children && <div>{children}</div>}
    </div>
  </div>
)

export default EmptyData
