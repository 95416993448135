import { useOpointImpersonationState, useOpointUser } from '@opoint/authjs-react'
import { Loader, TooltipProvider } from '@opoint/infomedia-storybook'
import { MouseEvent, Suspense, useEffect } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Outlet, useLocation } from 'react-router-dom'
import { T, useT } from '@transifex/react'
import ChangeLogAnnouncement from '../../components/ChangeAnnouncement'
import mainStyles from '../../components/Main.module.scss'
import { useAppSelector } from '../../components/hooks/useAppSelector'
import { useSearchFiltersWithoutPeriod } from '../../components/hooks/useSearchFilters'
import CustomerViewOverflow from '../../components/impersonation/CustomerViewOverflow'
import { Navigation } from '../../components/layout/navigation/Navigation'
import DashboardModal from '../../components/layout/navigation/sections/dashboards/DashboardModal'
import ProductTours, { DisplayedTour } from '../../components/productTour/ProductTours'
import TemplateEditorDialog from '../../components/templates/TemplateEditor/Modal'
import { lazy } from '../../helpers/lazy'
import { getArticleDeleteModalState } from '../../selectors/articlesSelectors'
import { getCommentsModalState } from '../../selectors/commentsSelectors'
import { getNewPortalSupport, isUserArchiveOnly } from '../../selectors/settingsSelectors'
import {
  getAddArticleModalOpen,
  getArticleModalStatus,
  getDashboardModalState,
  getDeleteProfileConfirmationModalOpen,
  getFilterDetailModalOpen,
  getInfoCalcModalOpen,
  getIsContactsModalOpen,
  getIsReportHistoryModalOpen,
  getIsSettingsModalOpen,
  getIsShareArticleModalOpen,
  getIsSourceListsModalOpen,
  getIsUserInvitationModalOpen,
  getManageProfilesModalOpen,
  getManageTagsModalOpen,
  getStatsInfoModalOpen,
  getSupportIssueModalOpen,
  getSupportRequestModalOpen,
  isAlertRemoveArticleOpen,
  isDatepickerModalOpen,
  isEditArticleModalOpen,
  isEditorFormModalOpen,
  isEditorModalOpen,
  isHelpModalOpen,
  isReportModalOpen,
  isSortableModuleModalOpen,
} from '../../selectors/uiSelectors'
import * as intercom from '../../services/intercom'
import ErrorBoundary from '../../components/common/ErrorBoundary'
import { setLocation } from '../../helpers/locationService'
import store from '../../store'
import { StorybookProvider } from '../../opoint/storybook/StorybookProvider'

// Pages
const ArchiveUserHomepage = lazy(() => import('../../components/ui/ArchiveUserHomepage'))

// Modals
const AddArticleModal = lazy(() => import('../../components/upload/AddArticleModal'))
const SearchDateRangeModal = lazy(() => import('../../components/common/SearchDateRangeModal'))
const ReportsModal = lazy(() => import('../../components/reports'))
const EditArticleModal = lazy(() => import('../../components/articles/Article/editArticle/EditArticleModal'))
const FilterDetailModal = lazy(() => import('../../components/search/FilterDetailModal'))
const HelpModal = lazy(() => import('../../components/help'))
const ManageProfilesModal = lazy(() => import('../../components/profile/manage'))
const ManageTagsModal = lazy(() => import('../../components/tags/manage'))
const ProfileDeleteConfirmationModal = lazy(() => import('../../components/profile/delete'))
const RemoveArticleModal = lazy(() => import('../../components/alerts/RemoveArticle/Modal'))
const SettingsModal = lazy(() => import('../../components/settings/Modal'))
const ShareArticleModal = lazy(() => import('../../components/share/ShareArticleModal'))
const EditTemplateModal = lazy(() => import('../../components/templates/TemplateEditor/EditTemplateModal'))
const SortableModuleModal = lazy(() => import('../../components/templates/TemplateEditor/SortableModuleModal'))
const InfoCalcModal = lazy(() => import('../../components/statistics/InfoCalcModal'))
const SupportIssueModal = lazy(() => import('../../components/supportModals/SupportIssueModal'))
const SupportRequestModal = lazy(() => import('../../components/supportModals/SupportRequestModal'))
const StatsInfoPopupModal = lazy(() => import('../../components/StatsInfoPopupModal/StatsInfoPopupModal'))
const ArticleModal = lazy(() => import('../../components/articles/Article/ArticleModal/ArticleModal'))
const CommentsModal = lazy(() => import('../../components/articles/Article/CommentsModal'))
const ArticleDeleteModal = lazy(() => import('../../components/layout/actionBar/search/modals/ArticleDeleteModal'))
const ReportHistoryDialog = lazy(() => import('../../components/reportsHistory/ReportHistoryDialog'))
const SourceListsDialog = lazy(() => import('../../components/source-list/SourceListsDialog'))
const ContactsModal = lazy(() => import('../../components/contacts/ContactsModal'))
const UserInvitationModal = lazy(() => import('../../components/userManagement/UserInvitationModal'))

// import CompareStatisticsPopupModal from '../../new-components/CompareStatisticsPopupModal'

const LoadingView = () => (
  <div className={mainStyles.loadingView}>
    <Loader />
  </div>
)

const LoadingOverlay = () => (
  <div className={mainStyles.loadingOverlay}>
    <Loader />
  </div>
)

const Modals = () => {
  const addArticleModalOpen = useAppSelector(getAddArticleModalOpen)
  const searchDateRangeModalOpen = useAppSelector(isDatepickerModalOpen)
  const reportsModalOpen = useAppSelector(isReportModalOpen)
  const editArticleModalOpen = useAppSelector(isEditArticleModalOpen)
  const filterDetailModalOpen = useAppSelector(getFilterDetailModalOpen)
  const helpModalOpen = useAppSelector(isHelpModalOpen)
  const removeArticleModalOpen = useAppSelector(isAlertRemoveArticleOpen)
  const manageProfilesModalOpen = useAppSelector(getManageProfilesModalOpen)
  const manageTagsModalOpen = useAppSelector(getManageTagsModalOpen)
  const profileDeleteConfirmationModalOpen = useAppSelector(getDeleteProfileConfirmationModalOpen)
  const settingsModalOpen = useAppSelector(getIsSettingsModalOpen)
  const reportHistoryModalOpen = useAppSelector(getIsReportHistoryModalOpen)
  const sourceListsModalOpen = useAppSelector(getIsSourceListsModalOpen)
  const contactsModalOpen = useAppSelector(getIsContactsModalOpen)
  const shareArticleModalOpen = useAppSelector(getIsShareArticleModalOpen)
  const templateEditorOpen = useAppSelector(isEditorModalOpen)
  const editTemplateModalOpen = useAppSelector(isEditorFormModalOpen)
  const sortableModuleModal = useAppSelector(isSortableModuleModalOpen)
  const infoCalcModalOpen = useAppSelector(getInfoCalcModalOpen)
  const supportIssueModal = useAppSelector(getSupportIssueModalOpen)
  const supportRequestModal = useAppSelector(getSupportRequestModalOpen)
  const statsInfoPopupModalOpen = useAppSelector(getStatsInfoModalOpen)
  const articleModalStatus = useAppSelector(getArticleModalStatus)?.status
  const isDashboardModalOpen = useAppSelector(getDashboardModalState).isOpen
  const isCommentsModalOpen = useAppSelector(getCommentsModalState)
  const isDeleteArticleModalOpen = useAppSelector(getArticleDeleteModalState)
  const isUserInvitationModalOpen = useAppSelector(getIsUserInvitationModalOpen)

  return (
    <Suspense fallback={null}>
      {addArticleModalOpen && <AddArticleModal />}
      {searchDateRangeModalOpen && <SearchDateRangeModal />}
      {reportsModalOpen && <ReportsModal />}
      {editArticleModalOpen && <EditArticleModal />}
      {filterDetailModalOpen && <FilterDetailModal />}
      {helpModalOpen && <HelpModal />}
      {removeArticleModalOpen && <RemoveArticleModal />}
      {manageProfilesModalOpen && <ManageProfilesModal />}
      {manageTagsModalOpen && <ManageTagsModal />}
      {profileDeleteConfirmationModalOpen && <ProfileDeleteConfirmationModal />}
      {contactsModalOpen && <ContactsModal />}
      {settingsModalOpen && <SettingsModal />}
      {reportHistoryModalOpen && <ReportHistoryDialog />}
      {sourceListsModalOpen && <SourceListsDialog />}
      {shareArticleModalOpen && <ShareArticleModal />}
      {templateEditorOpen && <TemplateEditorDialog />}
      {editTemplateModalOpen && <EditTemplateModal />}
      {sortableModuleModal && <SortableModuleModal />}
      {infoCalcModalOpen && <InfoCalcModal />}
      {supportIssueModal && <SupportIssueModal />}
      {supportRequestModal && <SupportRequestModal />}
      {statsInfoPopupModalOpen && <StatsInfoPopupModal />}
      {articleModalStatus && <ArticleModal />}
      {isDashboardModalOpen && <DashboardModal />}
      {isCommentsModalOpen && <CommentsModal />}
      {isDeleteArticleModalOpen && <ArticleDeleteModal />}
      {isUserInvitationModalOpen && <UserInvitationModal />}
      <CustomerViewOverflow />
      <ChangeLogAnnouncement />
    </Suspense>
  )
}

export const App = () => {
  const t = useT()
  const location = useLocation()
  const userArchiveOnly = useAppSelector(isUserArchiveOnly)
  const support = useAppSelector(getNewPortalSupport)

  const authImpersonationState = useOpointImpersonationState()
  const user = useOpointUser()
  const filters = useSearchFiltersWithoutPeriod()

  useEffect(() => {
    setLocation(location)
    store.dispatch({
      type: 'ROUTER_LOCATION_CHANGE',
      payload: { location },
    })
  }, [location])

  useEffect(() => {
    // Intercom is disabled on insiders, with Rocket chat used in its place (initialized in the index.html).
    if (!user || !support?.chat || import.meta.env.DEV || document.location.hostname.startsWith('insiders')) {
      return
    }

    intercom.init(user.user_id.toString())

    return () => {
      intercom.destroy()
    }
  }, [user, user?.user_id, support?.chat])

  const skipToAnchor = (anchor: string) => {
    const container: HTMLElement | null = document.querySelector(`#${anchor}`)

    if (container) {
      container.tabIndex = -1
      container.focus()
      setTimeout(() => container.removeAttribute('tabindex'), 1000)
    }
  }

  const skipTo = (e: MouseEvent, target: string) => {
    e.preventDefault()
    skipToAnchor(target)
  }

  const isMacLike = !!navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i)
  if (!isMacLike) {
    document.body.classList.add('styled-scrollbars')
  }

  const searchHome = ['/search/', '/search'].includes(location.pathname)

  const isArchiveUserAndHomepage = searchHome && userArchiveOnly && filters.length === 0

  // TODO: M360 Articles migration - craete separate component for StorybookProvider
  return (
    <StorybookProvider>
      <TooltipProvider>
        <DndProvider backend={HTML5Backend}>
          <div className="absolute left-0 top-1.5 -z-10 border border-sky.cloudy bg-grey.8 p-3 opacity-0 focus-within:z-10 focus-within:opacity-100">
            {`${t('Skip to')} `}
            <button
              className="cursor-pointer border-none bg-none text-sand.3"
              onClick={(event) => skipTo(event, 'menu-navigation')}
            >
              <T _str="menu" />
            </button>
            {', '}
            <button
              className="cursor-pointer border-none bg-none text-sand.3"
              onClick={(event) => skipTo(event, 'top-nav-search')}
            >
              <T _str="search" />
            </button>
            {` ${t('or')} `}
            <button
              className="cursor-pointer border-none bg-none text-sand.3"
              onClick={(event) => skipTo(event, 'content')}
            >
              <T _str="content" />
            </button>
          </div>

          <Navigation />

          <ErrorBoundary
            beforeCapture={(scope) => {
              scope.setTag('location', 'App')
            }}
            reloadButton={true}
            errorImg={true}
            contactSupport={true}
          >
            {authImpersonationState === undefined && <LoadingOverlay />}
            <div data-cy="main-content" id="main-content" className="h-full">
              <Suspense fallback={<LoadingView />}>
                {isArchiveUserAndHomepage ? <ArchiveUserHomepage /> : <Outlet />}
              </Suspense>
            </div>
          </ErrorBoundary>
          <Modals />

          <ProductTours
            displayedTours={[
              DisplayedTour.ALERTS,
              DisplayedTour.GENERAL_INTRO,
              DisplayedTour.TAGS,
              DisplayedTour.STATISTICS,
            ]}
          />
        </DndProvider>
      </TooltipProvider>
    </StorybookProvider>
  )
}

export default App
