import { PeopleSettings24Regular } from '@fluentui/react-icons'
import { ElementRef, forwardRef } from 'react'
import { NavLink } from 'react-router-dom'
import { t } from '@transifex/native'

import { NavigationComponentProps, NavigationSectionKey } from '../../../../helpers/navigation'
import { NavigationSection, NavigationSectionHeaderLink } from '../primitives/NavigationSection'

export const UserManagementNavigationSection = forwardRef<ElementRef<typeof NavLink>, NavigationComponentProps>(
  ({ isExpanded, ...props }, ref) => (
    <NavigationSection>
      <NavigationSectionHeaderLink
        label={t('User management')}
        isExpanded={Boolean(isExpanded)}
        ref={ref}
        to="/user-management/all"
        section={NavigationSectionKey.USER_MANAGEMENT}
        {...props}
      >
        <PeopleSettings24Regular className="w-7 shrink-0 text-sea.2" />
      </NavigationSectionHeaderLink>
    </NavigationSection>
  ),
)
UserManagementNavigationSection.displayName = 'UserManagementNavigationSection'
