import { tx } from '@transifex/native'
import { txNativeInstance } from '@opoint/infomedia-storybook'

const token = import.meta.env.VITE_TRANSIFEX_TOKEN

tx.init({
  token,
})

void tx.controllerOf(txNativeInstance)
