import { Temperature16Filled } from '@fluentui/react-icons'
import { cn, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@opoint/infomedia-storybook'
import { T } from '@transifex/react'

import { getFoldersTree } from '../../../../selectors/foldersSelectors'
import { getTags } from '../../../../selectors/tagsSelectors'
import { useAppSelector } from '../../../hooks/useAppSelector'
import useIsECBUser from '../../../hooks/useIsECBUser'
import useSpecialTags from '../../../hooks/useSpecialTags'
import { ArticleSpecialStatsKey, SentimentType } from '../../../types/article'
import { Tag } from '../../../types/tag'
import { M360Article } from '../../../../opoint/articles/types'

type FolderSentimentTooltipProps = {
  article: M360Article
  withIcon?: boolean
  mobileView?: boolean
  sentimentMarkerSize?: 'small' | 'medium' | 'large'
}

const FolderSentimentTooltip = ({
  article,
  withIcon = false,
  mobileView = false,
  sentimentMarkerSize = 'small',
}: FolderSentimentTooltipProps) => {
  const isECBUser = useIsECBUser()

  const tags: Array<Tag> = useAppSelector(getTags)
  const foldersTree = useAppSelector(getFoldersTree)

  const articleTags = Object.keys(article.tags ?? [])
  const sentimentTags = useSpecialTags(tags, articleTags, foldersTree, ArticleSpecialStatsKey.Sentiment)

  if (!isECBUser || sentimentTags.length === 0) {
    return null
  }

  const { name } = sentimentTags[0]

  const sentimentMarker = (
    <div
      className={cn('flex size-3 items-center justify-center rounded-full border border-grey.5', {
        'h-6 w-6': sentimentMarkerSize === 'large',
      })}
    >
      <div
        className={cn('size-2 rounded-full', {
          'bg-forest.3': name === SentimentType.Positive,
          'bg-sand.4': name === SentimentType.Neutral,
          'bg-berry.4': name === SentimentType.Negative,
          'bg-grey.6': name === SentimentType.Notset || name === SentimentType.Factual,
          'rounded-full bg-[conic-gradient(at_bottom,_var(--tw-gradient-stops))] from-berry.4  to-forest.3':
            name === SentimentType.Balanced,
          'h-[1.125rem] w-[1.125rem]': sentimentMarkerSize === 'large',
        })}
      ></div>
    </div>
  )

  const mainComponent = (
    <span className="flex items-center gap-0.5">
      {mobileView ? (
        <>
          <Temperature16Filled className="size-s" />
          {sentimentMarker}
        </>
      ) : (
        <Tooltip>
          <TooltipTrigger className="flex items-center">
            {withIcon && <Temperature16Filled className="size-5" />}
            {sentimentMarker}
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent side="top">{withIcon ? <T _str="Sentiment" /> : name}</TooltipContent>
          </TooltipPortal>
        </Tooltip>
      )}
    </span>
  )

  if (sentimentMarkerSize === 'large') {
    return (
      <li className="flex flex-col space-y-2.5">
        <div className="whitespace-nowrap text-label-s font-bold text-sky.cloudy">
          <T _str="Sentiment" />
        </div>
        <div className="flex flex-1 items-center whitespace-nowrap text-label-l font-medium text-sky.1">
          {mainComponent}
        </div>
      </li>
    )
  }

  return mainComponent
}

export default FolderSentimentTooltip
