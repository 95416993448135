import { T } from '@transifex/react'
import React from 'react'

import { Dialog, DialogBody, DialogContent, DialogHeader, DialogTitle } from '@opoint/infomedia-storybook'
import SuspenseWithLoading from '../../../common/Modal/SuspenseWithLoading'
import { M360Article } from '../../../../opoint/articles/types'
import Categorization from '.'

type CategorizationModalProps = {
  article: M360Article
  isOpen: boolean
  onCloseModal: () => void
}

const CategorizationModal = ({ article, isOpen, onCloseModal }: CategorizationModalProps): React.JSX.Element => (
  <Dialog open={isOpen} onOpenChange={onCloseModal}>
    <DialogContent onOpenAutoFocus={(e) => e.preventDefault()}>
      <DialogHeader>
        <DialogTitle>
          <T _str="Categorization" />
        </DialogTitle>
      </DialogHeader>
      <DialogBody>
        <SuspenseWithLoading>
          <Categorization article={article} />
        </SuspenseWithLoading>
      </DialogBody>
    </DialogContent>
  </Dialog>
)

export default CategorizationModal
