import {
  HighlightedMatchesAndEntities,
  SOCIAL_SOURCES,
  cn,
  isSocialArticle,
  splitBodyTextToParagraphs,
} from '@opoint/infomedia-storybook'
import { T } from '@transifex/react'
import { useState } from 'react'

import { LogActions } from '../../../../../opoint/articles'
import SocialContent from '../../SocialContent'
import ArticleHero from '../ArticleHero'
import ArticleImageGallery from '../ArticleImageGallery/ArticleImageGallery'
import ArticleSummary from '../ArticleSummary'
import EditableText from '../EditableText'
import { M360Article } from '../../../../../opoint/articles/types'
import Expand from '../../../../Transition/Expand'
import { BROADCAST_MEDIA_TYPES } from '../../../../../opoint/common/constants'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { getIsArchiveView } from '../../../../../selectors/settingsSelectors'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'

export type ArticleBodyProps = {
  article: M360Article
  editable: boolean
  isTwitterContent?: boolean
  onClickArticleLinkPdf: () => void
  onContentChange?: ({ body: { text } }: { body: { text: string } }) => void
  renderSocialIcon?: (profileImage: string, profileUrl: string) => JSX.Element
  socialContent_type?: string
  summaryText?: string
  expanded?: boolean
  shouldBeExpanded?: boolean
}

const TransitionWrapper = ({
  expanded,
  shouldBeExpanded,
  children,
}: {
  expanded?: boolean
  shouldBeExpanded?: boolean
  children
}) => {
  if (shouldBeExpanded) {
    return <div>{children}</div>
  }

  return <Expand in={!!expanded}>{children}</Expand>
}

const ArticleBody = ({
  expanded,
  editable,
  isTwitterContent,
  onClickArticleLinkPdf,
  onContentChange,
  renderSocialIcon,
  socialContent_type,
  summaryText,
  shouldBeExpanded,
  article,
}: ArticleBodyProps) => {
  const [openImageInGallery, setOpenImageInGallery] = useState<number | null>(null)
  const isArchiveView = useAppSelector(getIsArchiveView)

  const dispatch = useAppDispatch()
  const {
    articleimages,
    author,
    body,
    content_protected,
    extract,
    first_source,
    id_article,
    id_site,
    matches,
    mediatype,
    quotes,
    manual_summary,
    summary,
    social_meta,
  } = article

  const logArticleAction = () =>
    dispatch({ type: 'LOG_ARTICLE_ACTION', payload: { id_site, id_article, action: [LogActions.ImageClicked] } })

  const openGallery = () => {
    setOpenImageInGallery(0)
    logArticleAction()
  }
  const resetOpenedImage = (index: number | null) => {
    logArticleAction()
    setOpenImageInGallery(index)
  }

  const isSocial = isSocialArticle(article)
  const manualSummary = manual_summary
    ? {
        translated: manual_summary?.text,
        // @ts-expect-error: Muted so we could enable TS strict mode
        original: summary.text,
      }
    : null

  const hideSummary = isSocial && !summaryText && !manualSummary

  const { paragraphsToShow, paragraphsToExpand, allParagraphs } = splitBodyTextToParagraphs(body?.text)

  return (
    <>
      {(!BROADCAST_MEDIA_TYPES?.includes(mediatype?.text || '') || manualSummary) && !hideSummary && (
        <div className={isSocial && !editable ? 'px-3' : undefined}>
          <ArticleSummary
            author={author}
            // @ts-expect-error: Muted so we could enable TS strict mode
            bodyText={body.text}
            editable={editable}
            matches={matches}
            mediatype={mediatype}
            onContentChange={onContentChange}
            quotes={quotes}
            socialSource={SOCIAL_SOURCES[first_source.id]?.name}
            summaryText={summaryText}
            // @ts-expect-error: Muted so we could enable TS strict mode
            manualSummary={manualSummary}
          />
        </div>
      )}
      {socialContent_type !== 'link' && socialContent_type !== 'video' && (
        <ArticleHero article={article} onArticleHeroClick={openGallery} onArticleLinkClick={onClickArticleLinkPdf} />
      )}
      {/* SoMe video content (Currently only works for FB) */}
      {/* @ts-expect-error: Muted so we could enable TS strict mode */}
      {isSocial && <SocialContent type={socialContent_type} soMeAttachments={social_meta?.attachments} video={true} />}
      {editable ? (
        // @ts-expect-error: Muted so we could enable TS strict mode
        <EditableText bodyText={body.text} onContentChange={onContentChange} />
      ) : allParagraphs?.length > 0 ? (
        <div className={cn('relative font-slab text-body', { 'px-3 pt-3': isSocial })}>
          {paragraphsToShow?.map((text, index) => (
            <HighlightedMatchesAndEntities
              key={index}
              isExtract={extract}
              socialSource={SOCIAL_SOURCES[first_source.id]?.name}
              text={text}
            />
          ))}
          {isArchiveView ? (
            paragraphsToExpand.map((text, index) => (
              <HighlightedMatchesAndEntities
                key={index}
                isExtract={extract}
                socialSource={SOCIAL_SOURCES[first_source.id]?.name}
                text={text}
              />
            ))
          ) : (
            // @ts-expect-error: Muted so we could enable TS strict mode
            <Expand in={expanded && paragraphsToExpand.length > 0}>
              {paragraphsToExpand.map((text, index) => (
                <HighlightedMatchesAndEntities
                  key={index}
                  isExtract={extract}
                  socialSource={SOCIAL_SOURCES[first_source.id]?.name}
                  text={text}
                />
              ))}
            </Expand>
          )}

          {!isSocial && !expanded && paragraphsToExpand.length > 0 && (
            <div
              className="pointer-events-none absolute bottom-0 h-32 w-full bg-gradient-to-t from-white via-transparent to-transparent opacity-100 transition-opacity duration-300"
              aria-hidden={true}
            />
          )}
        </div>
      ) : null}
      <TransitionWrapper expanded={expanded} shouldBeExpanded={shouldBeExpanded}>
        <>
          {isSocial && (
            <SocialContent
              // @ts-expect-error: Muted so we could enable TS strict mode
              type={socialContent_type}
              soMeAttachments={social_meta?.attachments}
              author={author}
              content_protected={content_protected}
              // @ts-expect-error: Muted so we could enable TS strict mode
              renderSocialIcon={renderSocialIcon}
              isTwitterContent={isTwitterContent}
              first_source_id={first_source.id}
            />
          )}

          <ArticleImageGallery
            // @ts-expect-error: Muted so we could enable TS strict mode
            images={articleimages?.articleimage}
            editable={editable}
            id_article={id_article}
            // @ts-expect-error: Muted so we could enable TS strict mode
            openIndex={openImageInGallery}
            setOpenIndex={resetOpenedImage}
          />

          {(extract || !isSocial) && (
            <div className="flex flex-col gap-y-1 pb-6 pt-4 text-sm text-sky.cloudy">
              {extract && (
                <div>
                  <T _str="This is an extract" />
                </div>
              )}
            </div>
          )}
        </>
      </TransitionWrapper>
    </>
  )
}

export default ArticleBody
