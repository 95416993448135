import { cn, Popover, PopoverContent, PopoverTrigger } from '@opoint/infomedia-storybook'
import { T } from '@transifex/react'
import { ReactNode } from 'react'

import { SentimentType } from '../../../../types/article'

const sentimentStates = [SentimentType.Positive, SentimentType.Neutral, SentimentType.Negative]

const SentimentButton = ({
  type,
  isActive,
  onClick,
}: {
  type: SentimentType
  isActive: boolean
  onClick: (sentiment: SentimentType) => void
}) => (
  <button
    name="link"
    type="button"
    onClick={() => onClick(type)}
    className={cn('flex size-6 items-center justify-center rounded-full border-2 border-grey.5 hover:border-grey.4', {
      'border-sky.1 hover:border-sky.1': isActive,
    })}
  >
    <div
      className={cn('size-4 rounded-full', {
        'bg-forest.3': type === SentimentType.Positive,
        'bg-sand.4': type === SentimentType.Neutral,
        'bg-berry.4': type === SentimentType.Negative,
      })}
    />
  </button>
)

type Props = {
  isManuallySet: boolean
  onCompanySentimentChange: (boolean) => void
  shownCompanySentiment: string
  children: ReactNode
}

const SentimentPopover = ({ isManuallySet, children, shownCompanySentiment, onCompanySentimentChange }: Props) => {
  return (
    <Popover>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent className="w-72 opacity-100" side="bottom" variant="legacy">
        <div className="w-full">
          <div className="mb-3 mt-1.5">
            {shownCompanySentiment === SentimentType.Notset ? (
              <T
                _str="We were not able to automatically set your company sentiment on this article. Click below to set it
          manually."
              />
            ) : (
              <T _str="You can manually override our automatically generated assessment below" />
            )}
          </div>
          <div className="flex flex-col">
            <div className="flex gap-1">
              {sentimentStates.map((sentimentState, index) => (
                <SentimentButton
                  key={`${sentimentState}-${index}`}
                  isActive={isManuallySet && shownCompanySentiment === sentimentState}
                  type={sentimentState}
                  onClick={onCompanySentimentChange}
                />
              ))}
            </div>
            {isManuallySet && (
              <button
                name="link"
                type="button"
                className="mt-3 w-fit pl-0 text-left text-sand.3"
                onClick={() => onCompanySentimentChange(SentimentType.Notset)}
              >
                <T _str="Reset selection" />
              </button>
            )}
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
}

export default SentimentPopover
