import { ChevronLeft24Regular } from '@fluentui/react-icons'
import { Button, Checkbox, Label } from '@opoint/infomedia-storybook'
import { T, useT } from '@transifex/react'

import { ChangeEvent } from 'react'
import ContactsInput from '../../contacts/ContactsInput'
import { ContactsPath } from '../../types/contact'
import { FormElement } from '../FormComponents'
import TextInput from '../FormComponents/elements/textInput'

type Props = {
  shareMessage: string
  updateStep: (arg: { stepNumber: number }) => void
  handleMessageChange: (event: ChangeEvent<HTMLInputElement>) => void
  handleToggleAttachment: () => void
  isAttachmentChecked: boolean
  sendReport: () => void
  formName?: ContactsPath
  simpleForm: boolean
  recipientsErrorMsg?: string
  subjectErrorMsg?: string
}

const ShareContentInput = ({
  updateStep,
  sendReport,
  shareMessage,
  isAttachmentChecked,
  handleToggleAttachment,
  handleMessageChange,
  formName,
  simpleForm,
  recipientsErrorMsg = '',
  subjectErrorMsg = '',
}: Props) => {
  const t = useT()

  const handleCancelShare = () => {
    updateStep({ stepNumber: 1 })
  }

  const handleSendReport = () => {
    updateStep({ stepNumber: 3 })
    sendReport()
  }

  return (
    <div className="w-full">
      <FormElement>
        <label htmlFor="to">
          <T _str="To" />
        </label>
        <ContactsInput contactsPath={formName ?? ContactsPath.ShareArticlesContacts} error={recipientsErrorMsg} />
      </FormElement>
      <FormElement>
        <label htmlFor="message">
          <T _str="Subject" />
        </label>
        <TextInput
          id="message"
          type="text"
          placeholder={t('Message to recipients')}
          value={shareMessage}
          onChange={handleMessageChange}
          error={subjectErrorMsg}
        />
      </FormElement>

      <FormElement>
        <div className="flex items-center space-x-2">
          <Checkbox
            id="sendAsAttachment"
            name="sendAsAttachment"
            onCheckedChange={handleToggleAttachment}
            checked={isAttachmentChecked}
          />
          <Label htmlFor="sendAsAttachment" className="cursor-pointer leading-4 text-sky.1">
            <T _str="Send as an attachment" />
          </Label>
        </div>
      </FormElement>

      {!simpleForm && (
        <div className="flex items-center justify-center gap-x-2">
          <Button variant="outline" size="icon" onClick={handleCancelShare}>
            <ChevronLeft24Regular />
            <span className="sr-only">{t('Back')}</span>
          </Button>

          <Button onClick={handleSendReport}>
            <T _str="Send" />
          </Button>
        </div>
      )}
    </div>
  )
}

export default ShareContentInput
