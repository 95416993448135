import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogHeader,
  HighlightedMatchesAndEntities,
  ScrollArea,
  ScrollAreaViewPort,
} from '@opoint/infomedia-storybook'
import { T, useT } from '@transifex/react'
import { memo, useRef, useState } from 'react'
import { Virtuoso } from 'react-virtuoso'
import classNames from 'classnames'
import { MODULES } from '../../../../constants/permissions'
import useIsECBUser from '../../../../hooks/useIsECBUser'
import useIsUserAdmin from '../../../../hooks/useIsUserAdmin'
import useIsUserPermitted from '../../../../hooks/useIsUserPermitted'
import type { FirstSource, MediaMatch } from '../../../../types/article'

import { DocumentArticlemediaItem } from '../../../../../api/opoint-search-suggest.schemas'
import MatchItem from './MatchItem'

const convertSeconds = (seconds) => {
  const minutes = seconds > 60 ? seconds / 60 : '0'
  const readableMinutes = minutes.toString().split('.')[0]
  const readableSeconds = seconds - (readableMinutes === '0' ? 0 : parseInt(readableMinutes) * 60)

  const minutesString = parseInt(readableMinutes) < 10 ? '0' + readableMinutes : readableMinutes
  const secondsString = readableSeconds < 10 ? '0' + readableSeconds : readableSeconds

  return `${minutesString}:${secondsString}`
}

type Props = {
  articlemedia: DocumentArticlemediaItem[]
  first_source: FirstSource
  headerText: string
  isSocial: boolean
  mediaMatches: MediaMatch[]
  onClick: (seconds: number) => void
  shownVideo: { id: number; url: string }
}

const MatchList = ({ articlemedia, first_source, isSocial, mediaMatches, onClick, shownVideo, headerText }: Props) => {
  const t = useT()
  const isECBUser = useIsECBUser()

  const scrollAreaViewPort = useRef<HTMLDivElement>(null)

  const [transcriptOpened, setTranscriptOpened] = useState<boolean>(false)
  const [hideGradient, setHideGradient] = useState<boolean>(true)

  const isAdmin = useIsUserAdmin()
  const showCategorizationAsIcon = useIsUserPermitted({
    module: 'ENABLE_CATEGORIZATION',
    permissions: [MODULES.ENABLE_CATEGORIZATION.AS_ICON],
  })

  const isTrafikVerketAdmin = showCategorizationAsIcon && isAdmin

  const isYouTubeContent = isSocial && articlemedia && articlemedia[0].text && first_source.sitename === 'YouTube'

  const hasTranscript = articlemedia && articlemedia.length > 0
  const canViewFullTranscript = hasTranscript && (isECBUser || isTrafikVerketAdmin)

  if ((mediaMatches?.length > 0 && articlemedia) || isYouTubeContent) {
    return (
      <>
        <ul className="mb-3 rounded bg-grey.8 p-3">
          <li className="mb-6 flex flex-col items-start space-y-1 font-sans">
            {mediaMatches.length && (
              <span className="text-label-l font-bold">
                {mediaMatches.length > 1 ? (
                  <T _str="Your searchword was found {count} times in the video." count={mediaMatches.length} />
                ) : (
                  <T _str="Your searchword was found {count} time in the video." count={mediaMatches.length} />
                )}
              </span>
            )}

            <span className="text-label-s">
              <T _str="To be able to search in the video, our computers have transformed the video into text" />
            </span>

            {canViewFullTranscript && (
              <Button variant="text" onClick={() => setTranscriptOpened(true)} className="h-auto p-0 text-label-s">
                <T _str="Read full transcript" />
              </Button>
            )}
          </li>

          {isYouTubeContent && !mediaMatches.length ? (
            <li className="border-b border-b-grey.6 last:border-none">
              <div className="mb-3 text-label-s text-sky.cloudy [&>p]:text-label-s [&_.Match]:text-sky.1">
                <HighlightedMatchesAndEntities text={articlemedia[0].text} />
              </div>
            </li>
          ) : (
            <>
              <ScrollArea>
                <ScrollAreaViewPort ref={scrollAreaViewPort}>
                  <Virtuoso
                    className="max-h-[25rem] min-h-[5rem]" // Makes the height dynamic.
                    overscan={200}
                    customScrollParent={scrollAreaViewPort?.current ?? undefined}
                    data={mediaMatches}
                    atBottomStateChange={setHideGradient}
                    itemContent={(index: number, match) => (
                      <div className="py-0.5">
                        <MatchItem
                          key={index}
                          articlemedia={articlemedia}
                          match={match}
                          onClick={onClick}
                          onConvertSeconds={convertSeconds}
                          shownVideo={shownVideo}
                        />
                      </div>
                    )}
                  />

                  <span
                    className={classNames(
                      'pointer-events-none absolute bottom-0 left-0 h-48 w-full bg-gradient-to-t from-grey.8 via-transparent to-transparent transition-opacity duration-300',
                      {
                        'opacity-0': hideGradient,
                      },
                    )}
                    aria-hidden={true}
                  />
                </ScrollAreaViewPort>
              </ScrollArea>
            </>
          )}
        </ul>

        {canViewFullTranscript && (
          <Dialog open={transcriptOpened} onOpenChange={setTranscriptOpened}>
            <DialogContent>
              <DialogHeader className="font-bold">
                <HighlightedMatchesAndEntities text={headerText} showEntities={false} showMatches={false} />
              </DialogHeader>

              <DialogBody>
                <HighlightedMatchesAndEntities text={articlemedia[0].text} />
              </DialogBody>
            </DialogContent>
          </Dialog>
        )}
      </>
    )
  } else if (canViewFullTranscript) {
    const hasTranscript = !!articlemedia[0].text
    const transcriptText = hasTranscript ? articlemedia[0].text : t('No transcript.')

    return (
      <>
        <div className="mb-3 rounded bg-grey.7 p-3">
          <div className="mb-1.5 text-label-l font-medium">
            <T _str="Transcript:" />
          </div>

          <div className="mb-1.5 font-slab text-label-s text-sky.cloudy">
            <HighlightedMatchesAndEntities truncate={400} text={transcriptText} />
          </div>

          {hasTranscript && (
            <Button variant="text" onClick={() => setTranscriptOpened(true)} className="h-auto p-0 text-label-s">
              <T _str="Read full transcript" />
            </Button>
          )}
        </div>

        <Dialog open={transcriptOpened} onOpenChange={setTranscriptOpened}>
          <DialogContent>
            <DialogHeader className="font-bold">
              <HighlightedMatchesAndEntities text={headerText} showEntities={false} showMatches={false} />
            </DialogHeader>

            <DialogBody>
              <HighlightedMatchesAndEntities text={articlemedia[0].text} />
            </DialogBody>
          </DialogContent>
        </Dialog>
      </>
    )
  } else {
    return null
  }
}

export default memo(MatchList)
