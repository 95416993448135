import { ChevronRight20Regular, Mail24Regular, MoreVertical20Filled } from '@fluentui/react-icons'
import { Popover, PopoverContent, PopoverTrigger, cn } from '@opoint/infomedia-storybook'
import { useT } from '@transifex/react'
import { MouseEvent, forwardRef } from 'react'

import { isTagSelected } from '../../helpers/tags'
import { getLastUsedTag } from '../../selectors/tagsComposedSelectors'
import { useAppDispatch } from '../hooks/useAppDispatch'
import { useAppSelector } from '../hooks/useAppSelector'
import type { AlertTag as AlertTagType } from '../types/tag'

import { M360Article } from '../../opoint/articles/types'
import KeywordTag from './KeywordTag'

type AlertTagAction = { tag: AlertTagType; articles: M360Article[] }

type AlertTagProps = {
  article: M360Article
  tag: AlertTagType
  isOverflow?: boolean
  setOverflowDrilldown?: (arg: AlertTagType) => void
  setIdenticalArticleTag?: () => void
  handleUpdateTagWeight?: (increment: boolean) => void
}

const AlertTag = forwardRef<HTMLDivElement, AlertTagProps>(
  ({ article, tag, isOverflow = false, setOverflowDrilldown, setIdenticalArticleTag, handleUpdateTagWeight }, ref) => {
    const appDispatch = useAppDispatch()
    const t = useT()
    const lastUsedTag = useAppSelector(getLastUsedTag)

    const incrementTag: ({ tag, articles }: AlertTagAction) => void = (payload) =>
      appDispatch({ type: 'INCREMENT_TAG_WEIGHT_FOR_ARTICLE', payload })
    const setTag: ({ tag, articles }: AlertTagAction) => void = (payload) =>
      appDispatch({ type: 'TOGGLE_TAG_ARTICLE', payload })

    const onIncrementClickHandler = (e: MouseEvent<HTMLButtonElement>) => {
      if (lastUsedTag?.id === tag.id && setIdenticalArticleTag !== undefined) {
        e.stopPropagation()
        // @ts-expect-error: Muted so we could enable TS strict mode
        handleUpdateTagWeight(true)

        return
      }
      incrementTag({ tag, articles: [article] })
    }

    const onSetClickHandler = (e: MouseEvent<HTMLButtonElement>) => {
      if (lastUsedTag?.id === tag.id && setIdenticalArticleTag !== undefined) {
        e.stopPropagation()
        setIdenticalArticleTag()

        return
      }
      setTag({ tag, articles: [article] })
    }

    const isSelected = isTagSelected(tag, article.tags)
    const weight = article.tags?.[tag.id]?.weight ?? 1

    if (tag.children.length === 1) {
      return isOverflow ? (
        <div ref={ref} className="flex w-full hover:bg-grey.7">
          <button
            type="button"
            className={cn(
              'flex min-h-[2.5rem] grow cursor-pointer items-center gap-2 overflow-hidden break-all px-4 py-2 text-left text-label-l focus-visible:bg-grey.7 focus-visible:ring-0',
              {
                'font-medium': isSelected,
              },
            )}
            onClick={onSetClickHandler}
          >
            <Mail24Regular className="shrink-0" />
            <span className="truncate">{tag.subject}</span>
          </button>
          {isSelected && (
            <button
              type="button"
              className="inset-y-0 right-0 my-auto mr-4 h-6 w-6 shrink-0 rounded bg-grey.7 text-label-l"
              onClick={onIncrementClickHandler}
            >
              <span className="sr-only">{t('Increment tag weight')}</span>
              {weight}
            </button>
          )}
        </div>
      ) : (
        <div
          ref={ref}
          className={cn(
            'flex h-[1.875rem] cursor-pointer items-center justify-between rounded bg-grey.7 text-label-l',
            {
              'bg-sky.4 text-white': isSelected,
            },
          )}
        >
          <button
            type="button"
            className={cn('h-full overflow-hidden truncate px-3', {
              'pr-2.5': isSelected,
            })}
            onClick={onSetClickHandler}
          >
            <Mail24Regular className="mr-1 shrink-0" />
            <span>{tag.subject}</span>
          </button>
          {isSelected && (
            <button
              type="button"
              className="inline-block h-full border-l border-white/40 pl-2.5 pr-3 text-center"
              onClick={onIncrementClickHandler}
            >
              <span className="sr-only">{t('Increment tag weight')}</span>
              {weight}
            </button>
          )}
        </div>
      )
    }

    const onOverflowAlertClick = (e: MouseEvent) => {
      e.stopPropagation()
      // @ts-expect-error: Muted so we could enable TS strict mode
      setOverflowDrilldown(tag)
    }

    if (isOverflow) {
      return (
        <div ref={ref}>
          <button
            type="button"
            onClick={onOverflowAlertClick}
            className="flex min-h-[1.5rem] w-full shrink-0 cursor-pointer items-center gap-3 px-4 py-2 hover:bg-grey.7 focus-visible:bg-grey.7 focus-visible:ring-0"
          >
            <div
              className={cn('mr-auto flex items-center gap-2 break-all text-left text-label-l', {
                'font-medium': isSelected,
              })}
            >
              <Mail24Regular className="shrink-0" />
              <span>{tag.subject}</span>
            </div>
            <ChevronRight20Regular className="shrink-0" />
          </button>
        </div>
      )
    }

    return (
      <Popover>
        <PopoverTrigger asChild>
          <div
            ref={ref}
            className={cn(
              'flex min-h-[1.875rem] cursor-pointer items-center justify-between rounded bg-grey.7 px-3 text-label-l',
              {
                'bg-sky.4 text-white': isSelected,
              },
            )}
          >
            <Mail24Regular className="mr-1 shrink-0" />
            <span>{tag.subject}</span>
            <MoreVertical20Filled />
          </div>
        </PopoverTrigger>
        <PopoverContent side="top" className="w-60 break-all px-0 py-3">
          <ul>
            {tag.children.map((innerTag) => (
              <li key={innerTag.id}>
                <KeywordTag tag={innerTag} article={article} isOverflow />
              </li>
            ))}
          </ul>
        </PopoverContent>
      </Popover>
    )
  },
)
AlertTag.displayName = 'AlertTag'

export default AlertTag
