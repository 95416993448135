import {
  ArticlePreviewAuthor,
  ArticleSocialMediaType,
  DateVariant,
  formatDate,
  getArticleIcon,
  getArticleMediaTypeForIcon,
  HighlightedMatchesAndEntities,
} from '@opoint/infomedia-storybook'
import { T } from '@transifex/react'

import { getDateVariant, getOpointLocale } from '../../../../../selectors/settingsSelectors'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import ArticleSourceLineSource from '../ArticleSourceLineSource'
import { M360Article } from '../../../../../opoint/articles/types'

export type ArticleSourceLineProps = {
  article: M360Article
  edited: boolean
  editMode?: boolean
  articleIndex?: number
}

const ArticleSourceLine = ({ article, edited, editMode }: ArticleSourceLineProps) => {
  const {
    first_source,
    manual_summary,
    mediatype,
    orig_url,
    translated_header,
    unix_timestamp,
    id_site,
    author,
    topics,
  } = article

  const locale = useAppSelector(getOpointLocale)
  const dateVariant: DateVariant = useAppSelector(getDateVariant)

  const hasEditableHeadlineAndSummaryFields = translated_header && manual_summary

  const mediatypeIcon = getArticleMediaTypeForIcon({ topics: topics || [], mediatype: mediatype?.text })

  const socialMediaType = article.first_source.sitename.toLowerCase() as ArticleSocialMediaType

  //Temporary const
  // TODO: delete when we get the bool with the articles and use that instead
  const addedByUser = false

  return (
    <div className="mb-5 flex flex-col items-start">
      <div className="flex items-center text-sm font-bold">
        {formatDate({
          dateVariant: editMode ? 'absolute' : dateVariant,
          localeName: locale,
          unixTimestamp: unix_timestamp,
        })}
        <span className="px-1"> | </span>
        <span className="pr-1.5">
          {getArticleIcon({
            sourceType: mediatypeIcon,
            socialMediaType,
          })}
        </span>
        <ArticleSourceLineSource
          first_source={first_source}
          mediatype={mediatype}
          orig_url={orig_url}
          sourceIsLink={true}
          siteId={id_site}
          editMode={editMode}
        />
      </div>

      {!author && addedByUser ? (
        <ArticlePreviewAuthor>
          <T _str="Article added by user" />
        </ArticlePreviewAuthor>
      ) : (
        <>
          {author && (
            <ArticlePreviewAuthor>
              <HighlightedMatchesAndEntities text={author} />
            </ArticlePreviewAuthor>
          )}
          {edited && !hasEditableHeadlineAndSummaryFields && <T _str="Article edited by user" />}
        </>
      )}
    </div>
  )
}

export default ArticleSourceLine
