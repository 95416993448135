import { ChevronLeft20Regular } from '@fluentui/react-icons'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  PopoverContentProps,
} from '@opoint/infomedia-storybook'
import { PropsWithChildren, useState } from 'react'
import { AlertTag as AlertTagType, GeneralTag } from '../types/tag'

import ErrorBoundary from '../common/ErrorBoundary'
import { M360Article } from '../../opoint/articles/types'
import KeywordTag from './KeywordTag'
import OverflownTagsDropdown from './OverflownTagsDropdown'

type OverflowTagsPopoverProps = PropsWithChildren<{
  tags: GeneralTag[]
  article: M360Article
  side?: PopoverContentProps['side']
}>

const OverflowTagsPopover = ({ tags, article, side = 'top', children }: OverflowTagsPopoverProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [overflowDrilldown, setOverflowDrilldown] = useState<AlertTagType | null>(null)

  const handleOpenChange = () => {
    if (!isOpen) {
      setOverflowDrilldown(null)
    }

    setIsOpen((prev) => !prev)
  }

  const renderDrilldownTitle = () => (
    <button
      className="flex min-h-[2.5rem] w-full shrink-0 cursor-pointer items-center gap-1 border-b border-grey.6 px-4 pb-4"
      onClick={() => {
        setOverflowDrilldown(null)
      }}
    >
      <ChevronLeft20Regular className="shrink-0" />
      <span className="text-left">{overflowDrilldown?.subject}</span>
    </button>
  )

  return (
    <DropdownMenu open={isOpen} onOpenChange={handleOpenChange}>
      <DropdownMenuTrigger>{children}</DropdownMenuTrigger>
      <DropdownMenuContent side={side} className="max-h-[27.5rem] w-80 flex-col overflow-auto">
        <ErrorBoundary reloadButton={false} errorImg={false} contactSupport={false}>
          {overflowDrilldown?.subject && renderDrilldownTitle()}
          {/* @ts-expect-error: Muted so we could enable TS strict mode */}
          {overflowDrilldown?.children.length > 0 ? (
            // @ts-expect-error: Muted so we could enable TS strict mode
            overflowDrilldown.children.map((innerTag) => (
              <DropdownMenuItem key={innerTag.id}>
                <KeywordTag article={article} tag={innerTag} isOverflow />
              </DropdownMenuItem>
            ))
          ) : (
            <OverflownTagsDropdown article={article} tags={tags} setOverflowDrilldown={setOverflowDrilldown} />
          )}
        </ErrorBoundary>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default OverflowTagsPopover
