import { MoreVertical24Regular, Send24Regular, DismissCircle24Regular } from '@fluentui/react-icons'
import {
  AlertDialog,
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  AlertDialogTrigger,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogFooter,
  Button,
  toast,
} from '@opoint/infomedia-storybook'
import React, { useState } from 'react'
import { T, useT } from '@transifex/react'
import { SingleUserInvitationStatusEnum } from '../../../../../api/opoint-search-suggest.schemas'
import {
  getAuthInviteSearchRetrieveQueryKey,
  getAuthInviteRemainingInvitesRetrieveQueryKey,
  useAuthInviteResendEmailCreate,
} from '../../../../../api/auth/auth'
import { useInviteDirectDestroy, getInviteDirectListQueryKey } from '../../../../../api/invite/invite'
import { queryClient } from '../../../../../queryClient'
import { SingleUserInvitation } from '../../../../../api/opoint.schemas'

type UserReoveDialogProps = {
  invitation: SingleUserInvitation
}

const UserRevokeDialog = ({ invitation }: UserReoveDialogProps): React.JSX.Element => {
  const t = useT()
  const [isRevokeDialogOpen, setIsRevokeDialogOpen] = useState<boolean>(false)

  const handleRefetchQueries = async () => {
    await queryClient.refetchQueries(getAuthInviteSearchRetrieveQueryKey())
    await queryClient.refetchQueries(getInviteDirectListQueryKey())
    await queryClient.refetchQueries(getAuthInviteRemainingInvitesRetrieveQueryKey())
  }
  const { mutate: revokeInvitation } = useInviteDirectDestroy({
    mutation: {
      onSettled: () => {
        setIsRevokeDialogOpen(false)
      },
      onSuccess: async () => {
        handleRefetchQueries()

        toast({
          title: t('Invitation revoked'),
          variant: 'success',
        })
      },
      onError: () => {
        toast({
          title: t('We were unable to revoke invitation'),
          variant: 'destructive',
        })
      },
    },
  })

  const { mutate: resendInvitation } = useAuthInviteResendEmailCreate({
    mutation: {
      onSuccess: () => {
        handleRefetchQueries()

        toast({
          title: t('Invitation resent'),
          variant: 'success',
        })
      },
      onError: () => {
        toast({
          title: t('We were unable to resend invitation'),
          variant: 'destructive',
        })
      },
    },
  })

  const handleResend = () => {
    resendInvitation({ params: { token: invitation.token } })
  }

  const handleRevoke = () => {
    revokeInvitation({ token: invitation.token })
  }
  return (
    <>
      {invitation.status !== SingleUserInvitationStatusEnum['NUMBER_2'] && (
        <AlertDialog open={isRevokeDialogOpen} onOpenChange={setIsRevokeDialogOpen}>
          <DropdownMenu modal={false}>
            <DropdownMenuTrigger asChild>
              <Button
                variant="outline"
                className="border-transparent bg-transparent hover:border-transparent hover:bg-transparent active:border-transparent"
                size="icon"
              >
                <MoreVertical24Regular />
                <span className="sr-only">
                  <T _str="More options..." />
                </span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem onClick={handleResend}>
                <Send24Regular className="h-6 w-6 shrink-0 text-sky.1" />
                <T _str="Resend invitation" className="flex h-6 items-center" />
              </DropdownMenuItem>
              <AlertDialogTrigger>
                <DropdownMenuItem>
                  <DismissCircle24Regular className="h-6 w-6 shrink-0 text-sky.1" />
                  <T _str="Revoke invitation" className="flex h-6 items-center" />
                </DropdownMenuItem>
              </AlertDialogTrigger>
            </DropdownMenuContent>
          </DropdownMenu>
          <AlertDialogContent
            onEscapeKeyDown={() => {
              setIsRevokeDialogOpen(false)
            }}
          >
            <AlertDialogHeader>
              <AlertDialogTitle className="mb-0">
                <T _str="Are you sure you want to revoke the invitation?" />
              </AlertDialogTitle>
            </AlertDialogHeader>
            <AlertDialogFooter className="w-full max-w-full">
              <Button onClick={handleRevoke} className="w-full">
                <T _str="Revoke invitation" />
              </Button>
              <Button onClick={() => setIsRevokeDialogOpen(false)} className="w-full" variant="outline">
                <T _str="Cancel" />
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      )}
    </>
  )
}

export default UserRevokeDialog
