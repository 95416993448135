import { MailDismiss24Regular } from '@fluentui/react-icons'
import { t } from '@transifex/native'
import { ElementRef, forwardRef } from 'react'
import { NavLink } from 'react-router-dom'

import { NavigationComponentProps, NavigationSectionKey } from '../../../../../helpers/navigation'
import { getAlertsList, getFirstAlertTag } from '../../../../../selectors/alertsSelectors'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { useDefaultHomeUrl } from '../../../../hooks/useDefaultHomeUrl'
import { NavigationSection, NavigationSectionHeaderLink } from '../../primitives/NavigationSection'
import { EditAlertContentNavigationList } from './EditAlertContentNavigationList'

export const EditAlertContentNavigationSection = forwardRef<ElementRef<typeof NavLink>, NavigationComponentProps>(
  ({ isExpanded, ...props }, ref) => {
    const firstAlertTag = useAppSelector(getFirstAlertTag)
    const defaultHomeUrl = useDefaultHomeUrl()
    const alerts = useAppSelector(getAlertsList)

    const baskets = alerts.find(({ id }) => id === firstAlertTag?.id)?.baskets

    return (
      <NavigationSection>
        <NavigationSectionHeaderLink
          label={t('Edit alert content')}
          isExpanded={Boolean(isExpanded)}
          ref={ref}
          to={
            firstAlertTag?.id && baskets
              ? `/search/?filters=alertId:${firstAlertTag.id};tag:${baskets[0].id}`
              : defaultHomeUrl
          }
          section={NavigationSectionKey.EDIT_ALERT_CONTENT}
          {...props}
        >
          <MailDismiss24Regular className="w-7 shrink-0 text-sand.4" />
        </NavigationSectionHeaderLink>

        <EditAlertContentNavigationList />
      </NavigationSection>
    )
  },
)
EditAlertContentNavigationSection.displayName = 'EditAlertContentNavigationSection'
