import { t } from '@transifex/native'

import { ArticleSimilarWeb } from '@opoint/infomedia-storybook'
import { ReactNode } from 'react'
import { ArticleMetadataType } from '../../../../../types/article'
import { LocaleKey } from '../../../../../types/locale'
import { Tag } from '../../../../../types/tag'
import FolderSentimentTooltip from '../../../FolderSentimentTooltip/FolderSentimentTooltip'
import { DocumentSiteRank } from '../../../../../../api/opoint-search-suggest.schemas'
import { M360Article } from '../../../../../../opoint/articles/types'

export type ArticleMetadataBoxProps = {
  article?: M360Article
  countrycode?: string
  countryname?: string
  locale: LocaleKey
  reach?: number
  similarweb: ArticleSimilarWeb
  site_rank?: DocumentSiteRank
  type: ArticleMetadataType
  word_count: number
  sentimentTag?: Tag
}

export const ARTICLE_METADATA_CONFIG: {
  [key in ArticleMetadataType]: {
    title: (similarweb?: ArticleSimilarWeb) => string
    value: (
      articlePayload: {
        article?: M360Article
        countryname?: string
        reach?: number
        similarweb: ArticleSimilarWeb
        site_rank?: DocumentSiteRank
        word_count: number
        sentimentTag?: Tag
      },
      locale?: LocaleKey,
    ) => ReactNode
  }
} = {
  [ArticleMetadataType.Country]: {
    title: () => t('Country'),
    value: ({ countryname }) => countryname,
  },
  [ArticleMetadataType.GlobalRank]: {
    title: () => t('Global rank'),
    value: ({ site_rank }, locale) => site_rank?.rank_global?.toLocaleString(locale),
  },
  [ArticleMetadataType.CountryRank]: {
    title: () => t('Country rank'),
    value: ({ site_rank }, locale) => site_rank?.rank_country?.toLocaleString(locale),
  },
  [ArticleMetadataType.PagePerVisit]: {
    title: () => t('Pages per visit'),
    value: ({ similarweb }) => similarweb?.page_per_visit?.toFixed(2),
  },
  [ArticleMetadataType.BounceRate]: {
    title: () => t('Bounce rate'),
    value: ({ similarweb }) => {
      if (!similarweb?.bounce_rate) {
        return null
      }

      return `${(similarweb.bounce_rate * 100).toFixed(1)}%`
    },
  },
  [ArticleMetadataType.Words]: {
    title: () => t('Words'),
    value: ({ word_count }, locale) => word_count?.toLocaleString(locale),
  },
  // TODO: Not implemented by the backend yet. Uncomment whenever it's been done.
  // reach: {
  //   title: () => t('Reach'),
  //   value: ({ reach }: any, locale: any) => reach?.toLocaleString(locale),
  // },
  [ArticleMetadataType.ReadersSoFar]: {
    title: (similarweb) => {
      const stdExpectedPValue = similarweb?.article_readership?.StdExpectedPValue

      if (!stdExpectedPValue) {
        return t('Readers')
      }

      return t('Readers so far')
    },
    value: ({ similarweb }, locale) => similarweb?.article_readership?.value?.toLocaleString(locale),
  },
  [ArticleMetadataType.NewReadersPerDay]: {
    title: () => t('New readers/hour'),
    value: ({ similarweb }, locale) => similarweb?.article_readership?.DeltaPValue?.toLocaleString(locale),
  },
  [ArticleMetadataType.AverageReadership]: {
    title: () => t('Average readership'),
    value: ({ similarweb }, locale) => similarweb?.readership?.value?.toLocaleString(locale),
  },
  [ArticleMetadataType.MonthlyVisits]: {
    title: () => t('Monthly Visits'),
    value: ({ similarweb }, locale) => similarweb?.mvisit?.toLocaleString(locale),
  },
  [ArticleMetadataType.UniqueMobileVisits]: {
    title: () => t('Unique Mobile Visits'),
    value: ({ similarweb }, locale) => similarweb?.unique_mobile_visit?.toLocaleString(locale),
  },
  [ArticleMetadataType.UniqueDesktopVisits]: {
    title: () => t('Unique Desktop Visits'),
    value: ({ similarweb }, locale) => similarweb?.unique_desktop_visit?.toLocaleString(locale),
  },
  [ArticleMetadataType.UniqueDailyVisits]: {
    title: () => t('Unique Daily Visits'),
    value: ({ similarweb }, locale) => similarweb?.unique_daily_visit?.toLocaleString(locale),
  },
  [ArticleMetadataType.UniqueVisits]: {
    title: () => t('Unique Visits'),
    value: ({ similarweb }, locale) => similarweb?.unique_visit?.toLocaleString(locale),
  },
  [ArticleMetadataType.TimeOnSite]: {
    title: () => t('Time on Site'),
    value: ({ similarweb }, locale) =>
      similarweb?.time_on_site
        ? t('{ timeOnSite } sec', {
            timeOnSite: similarweb?.time_on_site?.toLocaleString(locale),
          })
        : '',
  },
  [ArticleMetadataType.FinalReaders]: {
    title: () => t('Final readers'),
    value: ({ similarweb }, locale) => {
      const stdExpectedPValue = similarweb?.article_readership?.StdExpectedPValue

      const expectedValue = similarweb?.article_readership?.ExpectedPValue

      const suffix = stdExpectedPValue ? ` ± ${stdExpectedPValue?.toLocaleString(locale)}` : ''

      if (!expectedValue) {
        return null
      }

      return `${expectedValue?.toLocaleString(locale)}${suffix}`
    },
  },
  [ArticleMetadataType.EcbSentiment]: {
    title: () => t('Sentiment'),
    value: () => null,
  },
}

const ArticleMetadataBox = ({
  article,
  countryname,
  locale,
  reach,
  similarweb,
  site_rank,
  type,
  word_count,
  sentimentTag,
}: ArticleMetadataBoxProps) => {
  if (type === 'ecb_sentiment') {
    return <>{article && <FolderSentimentTooltip article={article} sentimentMarkerSize="large" />}</>
  }

  const data = ARTICLE_METADATA_CONFIG[type]
  const value = data?.value({ article, countryname, reach, similarweb, site_rank, word_count, sentimentTag }, locale)

  if (!value) {
    return null
  }

  return (
    <li className="flex flex-col space-y-2.5">
      <div className="whitespace-nowrap text-label-s font-bold text-sky.cloudy">{data.title(similarweb)}</div>
      <div className="flex flex-1 items-center whitespace-nowrap text-label-l font-medium text-sky.1">{value}</div>
    </li>
  )
}

export default ArticleMetadataBox
