import { t } from '@transifex/native'
import { switchMap } from 'rxjs/operators'

import { ActionsObservable } from 'redux-observable'
import {
  SaveTemplateFailureAction,
  SaveTemplateSuccessAction,
  TemplateActions,
  TemplateDeleteFailureAction,
  TemplateDeleteSuccessAction,
  TemplateNewFailureAction,
  TemplateNewSuccessAction,
} from '../../../actions/templates'
import { ErrorEpic, handlerError } from '.'

const templateErrorTypes: ErrorEpic<
  | SaveTemplateSuccessAction['type']
  | TemplateNewSuccessAction['type']
  | TemplateDeleteSuccessAction['type']
  | SaveTemplateFailureAction['type']
  | TemplateNewFailureAction['type']
  | TemplateDeleteFailureAction['type']
> = {
  ['TEMPLATE_SAVE_SUCCESS']: {
    isSuccess: true,
    message: t('We successfully saved your template'),
    toastId: 'TEMPLATE_SAVE_SUCCESS',
  },
  ['TEMPLATE_NEW_SUCCESS']: {
    isSuccess: true,
    message: t('We successfully created new template'),
    toastId: 'TEMPLATE_NEW_SUCCESS',
  },
  ['TEMPLATE_DELETE_SUCCESS']: {
    isSuccess: true,
    message: t('We successfully deleted template'),
    toastId: 'TEMPLATE_DELETE_SUCCESS',
  },
  ['TEMPLATE_SAVE_FAILURE']: {
    isSuccess: false,
    message: t('We were not able to save your template'),
    toastId: 'TEMPLATE_SAVE_FAILURE',
  },
  ['TEMPLATE_NEW_FAILURE']: {
    isSuccess: false,
    message: t('We were not able to create new template'),
    toastId: 'TEMPLATE_NEW_FAILURE',
  },
  ['TEMPLATE_DELETE_FAILURE']: {
    isSuccess: false,
    message: t('We were not able to delete your template'),
    toastId: 'TEMPLATE_DELETE_FAILURE',
  },
}

const templateErrorEpics: (action: ActionsObservable<TemplateActions>) => void = (action$) =>
  action$.pipe(switchMap(({ type }) => handlerError(templateErrorTypes, type)))

export default templateErrorEpics
